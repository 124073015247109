export const i18bg = {
    accommodation_in_vt: "наеми във Велико Търново",
    apartments: "Апартаменти",
    rooms: "Стаи",
    houses: "Къщи",
    offices: "Офиси",
    shops: "Магазини",
    restourants: "Ресторанти - кафе",
    apartments_in_vt: "апартаменти във Велико Търново",
    promo_prices: "имоти с намалени цени",
    no_commission_fee: "без комисионна",
    properties_virtualtour: "имоти с виртуална обиколка",
    exclusive_seller: "ексклузивен продавач"
}

export const i18en = {
    accommodation_in_vt: "rent in Veliko Tarnovo",
    apartments: "Apartments",
    rooms: "Rooms",
    houses: "Houses",
    offices: "Offices",
    shops: "Shops",
    restourants: "Restaurant - cafe",
    apartments_in_vt: "apartments in Veliko Tarnovo",
    promo_prices: "reduced prices",
    no_commission_fee: "no comission fee",
    properties_virtualtour: "properties in virtual tour",
    exclusive_seller: "exclusive seller"
}