export const i18bg = {
    saleorrent: "Продажба или наем",
    sale: "Продажба",
    rent: "Наем",
    propertytype: "Вид на имота",
    propertylocation: "Местоположение",
    price: "Цена",
    searchbuttondefault: "Търсене във всички имоти",
    searchbuttonstart: "Търсене в ",
    searchbuttonend: " имота"
}

export const i18en = {
    saleorrent: "Sale or rent",
    sale: "Sale",
    rent: "Rent",
    propertytype: "Property type",
    propertylocation: "Location",
    price: "Price",
    searchbuttondefault: "Search in all properties",
    searchbuttonstart: "Search in ",
    searchbuttonend: " properties"
}