import React, { Component } from "react";
import { connect } from "react-redux"
import axios from "axios"
import { Link } from "react-router-dom"
import BlogPost from "./BlogPost"
import { globalhostapi } from "./../config/variables"
import { i18bg, i18en } from "./../locale/landingblog"

class LandingBlog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allPosts: [],
            postPage: 0,
        };

        this.loadLess = this.loadLess.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount() {
        axios(globalhostapi + "blog/" + this.props.lang + "/post/6")
            .then(response => {
                this.setState({
                    allPosts: response.data,
                });
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lang !== this.props.lang) {
            axios(globalhostapi + "blog/" + this.props.lang + "/post/6")
            .then(response => {
                this.setState({
                    allPosts: response.data,
                });
            });
        }
    }

    loadLess() {
        this.setState({
            postPage: 0,
        });
    }

    loadMore() {
        if (this.state.allPosts[3] && Object.keys(this.state.allPosts[3]).length > 0) {
            this.setState({
                postPage: 3,
            });
        }
    }

    render() {
        return (
            <div className="block s-fs" style={{ backgroundColor: "#e7ece7" }}>
                <h1 className="landingNewsTitle">
                    {this.props.lang === "bg"? i18bg.title : i18en.title}
                </h1>
                <p className="landingNewsSubtitle">
                    {this.props.lang === "bg"? i18bg.subtitle : i18en.subtitle}
                </p>
                <div
                    className="block s-md"
                    style={{ paddingTop: 54, paddingBottom: 9 }}
                >
                    <div className="landingNews">
                        <div className="newsItems">
                            {this.state.allPosts[0] && <BlogPost data={this.state.allPosts[0 + this.state.postPage]} />}
                            {this.state.allPosts[1] && <BlogPost data={this.state.allPosts[1 + this.state.postPage]} />}
                            {this.state.allPosts[2] && <BlogPost data={this.state.allPosts[2 + this.state.postPage]} />}
                        </div>

                        {this.state.postPage === 3 && <div className="load_less shadow" onClick={this.loadLess}></div>}
                        {this.state.allPosts.length > 3 && this.state.postPage === 0? <div className="load_more shadow" onClick={this.loadMore}></div> : ""}
                    </div>
                </div>
                <div
                    className="block s-md"
                    style={{ paddingTop: 15, paddingBottom: 9 }}
                >
                    <div className="allNews">
                        <Link to="/blog">
                            {this.props.lang === "bg"? i18bg.allnews : i18en.allnews}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang
    };
  };
  
  export default connect(mapStateToProps)(LandingBlog)
