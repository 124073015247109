import React, { Component } from "react";
import axios from "axios";
import { globalhostapi } from "./../config/variables"
import toastr from "toastr";
import ManagementNav from "../Components/ManagementNav";

class ManagementPlacesEditPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allRegions: [],

            id: 0,
            bg_city: "",
            en_city: "",
            c_id: 0,
            visible: "",
            region_id: 0,
            region_bg_city: "",
            region_en_city: "",
        };

        this.editPlace = this.editPlace.bind(this);
    }

    componentDidMount() {
        axios
            .get(
                globalhostapi + "place/one/" +
                    this.props.match.params.id
            )
            .then((response1) => {
                this.setState(
                    {
                        id: response1.data.id,
                        bg_city: response1.data.bg_city,
                        en_city: response1.data.en_city,
                        c_id: response1.data.c_id,
                        visible: response1.data.visible === "Y" ? "checked" : "",
                    },
                    () => {
                        axios
                            .get(
                                globalhostapi + "place/" +
                                    this.state.c_id +
                                    "/region"
                            )
                            .then((response2) => {
                                this.setState({
                                    region_id: response2.data.id,
                                    region_bg_city: response2.data.bg_city,
                                    region_en_city: response2.data.en_city,
                                });
                            })
                            .catch(function (error) {
                                console.log(error);
                            });

                        axios
                            .get(globalhostapi + "region/all")
                            .then((response) => {
                                this.setState({
                                    allRegions: response.data,
                                });
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                );
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    editPlace() {
        axios
            .post(
                globalhostapi + "place/edit/" +
                    this.props.match.params.id,
                {
                    id: this.state.id,
                    bg_city: this.state.bg_city,
                    en_city: this.state.en_city,
                    c_id: this.state.c_id,
                    sort: this.state.sort,
                    visible: this.state.visible === "checked" ? "Y" : "N",
                }
            )
            .then(() => {
                axios
                    .get(
                        globalhostapi + "place/" +
                            this.state.c_id +
                            "/region"
                    )
                    .then((response) => {
                        this.setState({
                            region_id: response.data.id,
                            region_bg_city: response.data.bg_city,
                            region_en_city: response.data.en_city,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                toastr.success("Успешно редактиран субрегион.");
            })
            .catch(() => {
                toastr.warning("Неуспешно редактирането на субрегион.");
            });
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="addRegion shadow">
                            <h3>Редактирай субрегион</h3>
                            <br />
                            <label htmlFor="bg_city">Име на български</label>
                            <input
                                type="text"
                                name="bg_city"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        bg_city: e.target.value,
                                    });
                                }}
                                value={this.state.bg_city}
                                required="required"
                            />
                            <label htmlFor="en_city">Име на английски</label>
                            <input
                                type="text"
                                name="en_city"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        en_city: e.target.value,
                                    });
                                }}
                                value={this.state.en_city}
                                required="required"
                            />
                            <label htmlFor="c_id">Регион</label>
                            <select
                                name="c_id"
                                onChange={(e) => {
                                    this.setState({
                                        c_id: e.target.value,
                                    });
                                }}
                                required="required"
                            >
                                {this.state.allRegions.length > 0
                                    ? this.state.allRegions.map(
                                          (region, id) => {
                                              return (
                                                  <option
                                                      value={region.id}
                                                      key={id}
                                                      selected={
                                                          region.id ===
                                                          this.state.c_id
                                                              ? "selected"
                                                              : ""
                                                      }
                                                  >
                                                      {region.bg_city}
                                                  </option>
                                              );
                                          }
                                      )
                                    : ""}
                            </select>
                            <label htmlFor="visible">Показване</label>
                            <input
                                type="checkbox"
                                name="visible"
                                checked={this.state.visible}
                                onChange={() => {
                                    if (this.state.visible === "checked") {
                                        this.setState({
                                            visible: "",
                                        });
                                    } else {
                                        this.setState({
                                            visible: "checked",
                                        });
                                    }
                                }}
                            />
                            <input
                                type="button"
                                onClick={this.editPlace}
                                value="Редактирай"
                            />
                        </div>
                        <div className="listProperties shadow"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementPlacesEditPage;
