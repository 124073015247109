export const i18bg = {
    title: "Последно добавени имоти",
    sqm: "кв.м.",
    floor: "етаж"
}

export const i18en = {
    title: "Last added properties",
    sqm: "sq.m.",
    floor: "floor"
}