import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { globalhostapi } from "./../config/variables"
import DatePicker from "react-datepicker";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Map, TileLayer, Marker } from "react-leaflet-universal"
import toastr from "toastr";

import ManagementNav from "./../Components/ManagementNav";

import "react-datepicker/dist/react-datepicker.css";

class ManagementPropertiesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allSubtypes: [],
            allRegions: [],
            regionPlaces: [],
            allBrokers: [],
            allProperties: [],

            searchById: 0,
            limit_skip: 0,

            add_transaction_id: "",
            add_type_id: "",
            add_region_id: "",
            add_place_id: "",
            add_broker_id: "",
            add_bg_description: "",
            add_en_description: "",
            add_date: new Date(),
            add_pictures: [],
            add_picturesUrls: [],
            add_price: "",
            add_price_type: "",
            add_square: "",
            add_floor: "",
            add_no_commission: "",
            add_promo: "",
            add_new_offer: "",
            add_status: "",
            add_sort: 10,
            add_visible: "checked",
            add_virtualtour: "",
            viewport: {
                center: [43.0776856, 25.6282174],
                zoom: 16
            },
            latitude: 43.0776856,
            longitude: 25.6282174
        };

        this.addProperty = this.addProperty.bind(this);
        this.deleteProperty = this.deleteProperty.bind(this);
        this.load20More = this.load20More.bind(this);
        this.load20Less = this.load20Less.bind(this);
        this.searchById = this.searchById.bind(this);
        this.onViewportChanged = this.onViewportChanged.bind(this)
        this.updateMarkerPosition = this.updateMarkerPosition.bind(this)
    }

    componentDidMount() {
        axios
            .post(globalhostapi + "property/limit", {
                limit_skip: this.state.limit_skip,
            })
            .then((response) => {
                this.setState({
                    allProperties: response.data,
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });

        axios
            .get(globalhostapi + "subtype/all")
            .then((response) => {
                this.setState(
                    {
                        allSubtypes: response.data,
                    },
                    () => {
                        this.setState({
                            add_region_id: this.state.allSubtypes[0].id,
                        });
                    }
                );
            })
            .catch(function (error) {
                console.log(error);
            });

        axios
            .get(globalhostapi + "region/all")
            .then((response1) => {
                this.setState(
                    {
                        allRegions: response1.data,
                    },
                    () => {
                        axios
                            .get(
                                globalhostapi + "region/" +
                                    response1.data[0].id +
                                    "/places"
                            )
                            .then((response2) => {
                                this.setState({
                                    regionPlaces: response2.data,
                                });
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                );
            })
            .catch(function (error) {
                console.log(error);
            });

        axios
            .get(globalhostapi + "broker/all")
            .then((response) => {
                this.setState(
                    {
                        allBrokers: response.data,
                    },
                    () => {
                        this.setState({
                            add_broker_id: this.state.allBrokers[0].id,
                        });
                    }
                );
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    addProperty(e) {
        e.preventDefault();

        let formatDate = this.state.add_date.getFullYear();

        if (this.state.add_date.getMonth() < 10) {
            formatDate = formatDate + "-0" + this.state.add_date.getMonth();
        } else {
            formatDate = formatDate + "-" + this.state.add_date.getMonth();
        }

        if (this.state.add_date.getDate() < 10) {
            formatDate = formatDate + "-0" + this.state.add_date.getDate();
        } else {
            formatDate = formatDate + "-" + this.state.add_date.getDate();
        }

        formatDate =
            formatDate +
            " " +
            this.state.add_date.getHours() +
            ":" +
            this.state.add_date.getMinutes() +
            ":00";

        const formData = new FormData();

        formData.append("transaction_id", this.state.add_transaction_id);
        formData.append("type_id", this.state.add_type_id);
        formData.append("place_id", this.state.add_place_id);
        formData.append("broker_id", this.state.add_broker_id);
        formData.append("bg_description", this.state.add_bg_description);
        formData.append("en_description", this.state.add_en_description);
        formData.append("date", formatDate);
        formData.append("price", this.state.add_price);
        formData.append("price_type", this.state.add_price_type);
        formData.append("square", this.state.add_square);
        formData.append("floor", this.state.add_floor);
        formData.append(
            "no_commission",
            this.state.add_no_commission === "checked" ? "Y" : "N"
        );
        formData.append(
            "promo",
            this.state.add_promo === "checked" ? "Y" : "N"
        );
        formData.append(
            "new_offer",
            this.state.add_new_offer === "checked" ? "Y" : "N"
        );
        formData.append("status", this.state.add_status);
        formData.append("sort", this.state.add_sort);
        formData.append(
            "visible",
            this.state.add_visible === "checked" ? "Y" : "N"
        );

        formData.append("latitude", this.state.latitude);
        formData.append("longitude", this.state.longitude);
        formData.append("zoom", this.state.viewport.zoom);

        formData.append("virtualtour", this.state.add_virtualtour);

        formData.append("token", this.props.auth.user.token)
        formData.append("token_valid_until", this.props.auth.user.token_valid_until)

        axios
            .post(globalhostapi + "property/add", formData)
            .then((response) => {
                if (typeof response.data === "object") {
                    if (response.data.id) {
                        toastr.success("Успешно добавена обява.");

                        for (let picture of this.state.add_pictures) {

                            const pictureData = new FormData();

                            pictureData.append("id", response.data.id);
                            pictureData.append("picture", picture);
                            pictureData.append("token", this.props.auth.user.token)
                            pictureData.append("token_valid_until", this.props.auth.user.token_valid_until)

                            axios
                                .post(
                                    globalhostapi + "property/edit/pictures",
                                    pictureData
                                )
                                .then(() => toastr.success("Успешно добавена снимка."))
                                .catch(() => toastr.warning("Неуспешно добавена снимка."));
                        }

                        this.setState({
                            add_transaction_id: 1,
                            add_type_id: 0,
                            add_region_id: 0,
                            add_place_id: 0,
                            add_broker_id: 0,
                            add_bg_description: "",
                            add_en_description: "",
                            add_date: new Date(),
                            add_pictures: [],
                            add_picturesUrls: [],
                            add_price: 0,
                            add_price_type: 0,
                            add_square: 0,
                            add_floor: 0,
                            add_no_commission: "",
                            add_promo: "",
                            add_new_offer: "",
                            add_status: 0,
                            add_sort: 10,
                            add_visible: "checked",
                            add_virtualtour: "",
                            viewport: {
                                center: [43.0776856, 25.6282174],
                                zoom: 16
                            },
                            latitude: 43.0776856,
                            longitude: 25.6282174
                        });

                        axios.post(globalhostapi + "property/limit", {
                                limit_skip: this.state.limit_skip,
                            })
                            .then((response) => {
                                this.setState({
                                    allProperties: response.data,
                                });
                            })
                            .catch((error) => {
                                console.error("Грешка:", error);
                            });
                    } else if (
                        response.data.id === undefined ||
                        response.data.id === null
                    ) {
                        Object.keys(response.data).map((error, i) =>
                            toastr.warning("Грешка: " + JSON.stringify(error))
                        );
                    }
                }
            })
            .catch((error) => console.error(error));
    }

    deleteProperty(id, name, city) {
        if (window.confirm("Изтрий " + name + ", " + city + "?")) {
            axios
                .post(globalhostapi + "property/delete/" + id, {
                    token: this.props.auth.user.token,
                    token_valid_until: this.props.auth.user.token_valid_until
                })
                .then((response1) => {
                    if (response1.data === true) {
                        axios
                            .post(globalhostapi + "property/limit", {
                                limit_skip: this.state.limit_skip,
                            })
                            .then((response2) => {
                                this.setState({
                                    allProperties: response2.data,
                                });
                            })
                            .catch((error) => {
                                console.error("Error:", error);
                            });
                            toastr.success("Успешно изтрита обява.");
                    } else {
                        toastr.warning("Неуспешно изтриването на обява.");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    selectImages = (event) => {
        let images = [];
        for (var i = 0; i < event.target.files.length; i++) {
            images[i] = event.target.files.item(i);
        }
        images = images.filter((image) =>
            image.name.match(/\.(jpg|jpeg|png)$/)
        );
        this.setState({ add_pictures: images });
    };

    load20More() {
        this.setState(
            {
                limit_skip: this.state.limit_skip + 20,
            },
            () => {
                axios
                    .post(globalhostapi + "property/limit", {
                        limit_skip: this.state.limit_skip,
                    })
                    .then((response) => {
                        this.setState({
                            allProperties: response.data,
                        });
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            }
        );
    }

    load20Less() {
        this.setState(
            {
                limit_skip: this.state.limit_skip - 20,
            },
            () => {
                axios
                    .post(globalhostapi + "property/limit", {
                        limit_skip: this.state.limit_skip,
                    })
                    .then((response) => {
                        this.setState({
                            allProperties: response.data,
                        });
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            }
        );
    }

    searchById() {
        if (
            typeof this.state.searchById === "number" &&
            this.state.searchById > 0
        ) {
            axios
                .post(globalhostapi + "property/limit", {
                    id: this.state.searchById,
                })
                .then((response) => {
                    this.setState({
                        allProperties: response.data,
                    });
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            axios
                .post(globalhostapi + "property/limit", {
                    limit_skip: this.state.limit_skip,
                })
                .then((response) => {
                    this.setState({
                        allProperties: response.data,
                    });
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }

    onViewportChanged = (viewport) => {
        this.setState({ viewport })
    }

    refmarker = createRef()

    updateMarkerPosition = (e) => {
        let lat = e.target._latlng.lat
        let lng = e.target._latlng.lng
        this.setState({
            latitude: lat,
            longitude: lng
        })
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <form
                            className="addProperty shadow"
                            onSubmit={this.addProperty}
                            encType="multipart/form-data"
                        >
                            <h3>Добави обява / <Link to="/management/import" style={{ color: "#3e4095" }}>Импортирай</Link></h3>
                            <br />
                            <label htmlFor="type_offer">Вид обява</label>
                            <select
                                name="type_offer"
                                onChange={(e) => {
                                    this.setState({
                                        add_transaction_id: e.target.value,
                                    });
                                }}
                                required="required"
                            >
                                <option value="">Избери</option>
                                <option value="1">Продажби</option>
                                <option value="2">Наеми</option>
                            </select>
                            <label htmlFor="type_id">Тип на имота</label>
                            <select
                                name="type_id"
                                onChange={(e) => {
                                    this.setState({
                                        add_type_id: e.target.value,
                                    });
                                }}
                                required="required"
                            >
                                <option value="">Избери</option>
                                {this.state.allSubtypes.length > 0
                                    ? this.state.allSubtypes.map(
                                          (subtype, id) => {
                                              return (
                                                  <option
                                                      value={subtype.id}
                                                      key={id}
                                                  >
                                                      {subtype.bg_name}
                                                  </option>
                                              );
                                          }
                                      )
                                    : ""}
                            </select>
                            <label htmlFor="region_id">Населено място</label>
                            <select
                                name="region_id"
                                onChange={(e) => {
                                    this.setState(
                                        {
                                            add_region_id: e.target.value,
                                        },
                                        () => {
                                            axios
                                                .get(
                                                    globalhostapi + "region/" +
                                                        this.state
                                                            .add_region_id +
                                                        "/places"
                                                )
                                                .then((response) => {
                                                    this.setState({
                                                        regionPlaces:
                                                            response.data,
                                                    });
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                });
                                        }
                                    );
                                }}
                                required="required"
                            >
                                <option value="">Избери</option>
                                {this.state.allRegions.length > 0
                                    ? this.state.allRegions.map(
                                          (region, id) => {
                                              return (
                                                  <option
                                                      value={region.id}
                                                      key={id}
                                                  >
                                                      {region.bg_city}
                                                  </option>
                                              );
                                          }
                                      )
                                    : ""}
                            </select>
                            <label htmlFor="place_id">Местоположение</label>
                            <select
                                name="place_id"
                                onChange={(e) => {
                                    this.setState({
                                        add_place_id: e.target.value,
                                    });
                                }}
                                required="required"
                            >
                                <option value="">Избери</option>
                                {this.state.regionPlaces.length > 0
                                    ? this.state.regionPlaces.map(
                                          (place, id) => {
                                              return (
                                                  <option
                                                      value={place.id}
                                                      key={id}
                                                  >
                                                      {place.bg_city}
                                                  </option>
                                              );
                                          }
                                      )
                                    : ""}
                            </select>
                            <label htmlFor="broker_id">Брокер</label>
                            <select
                                name="broker_id"
                                onChange={(e) => {
                                    this.setState({
                                        add_broker_id: e.target.value,
                                    });
                                }}
                                required="required"
                            >
                                <option value="">Избери</option>
                                {this.state.allBrokers.length > 0
                                    ? this.state.allBrokers.map(
                                          (broker, id) => {
                                              return (
                                                  <option
                                                      value={broker.code}
                                                      key={id}
                                                  >
                                                      {broker.bg_names} ({broker.code})
                                                  </option>
                                              );
                                          }
                                      )
                                    : ""}
                            </select>
                            <label htmlFor="status">Статус</label>
                            <select
                                name="status"
                                onChange={(e) => {
                                    this.setState({
                                        add_status: e.target.value,
                                    });
                                }}
                                required="required"
                            >
                                <option value="">Избери</option>
                                <option value="0">Актуален</option>
                                <option value="1">Предложен</option>
                                <option value="2">Продаден/Отдаден</option>
                            </select>
                            <label htmlFor="price">Цена</label>
                            <input
                                type="text"
                                name="price"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        add_price: e.target.value,
                                    });
                                }}
                                value={this.state.add_price}
                                required="required"
                            />
                            <select
                                name="price_type"
                                onChange={(e) => {
                                    this.setState({
                                        add_price_type: e.target.value,
                                    });
                                }}
                                required="required"
                            >
                                <option value="">Избери</option>
                                <option value="0">€</option>
                                <option value="1">€ / m2</option>
                                <option value="2">€ / месец</option>
                                <option value="3">от €</option>
                                <option value="4">от € / m2</option>
                                <option value="5">от € / месец</option>
                                <option value="6">лв.</option>
                                <option value="7">/ m2</option>
                                <option value="8">/ месец</option>
                                <option value="9">от лв.</option>
                                <option value="10">от / m2</option>
                                <option value="11">от / месец</option>
                            </select>
                            <label htmlFor="square">Площ</label>
                            <input
                                type="text"
                                name="square"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        add_square: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="floor">Етаж</label>
                            <input
                                type="text"
                                name="floor"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        add_floor: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="date">Дата на добавяне</label>
                            <DatePicker
                                selected={this.state.add_date}
                                onChange={(date) =>
                                    this.setState(
                                        {
                                            add_date: date,
                                        },
                                        () => {}
                                    )
                                }
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                            />
                            <label htmlFor="sort">Приоритет</label>
                            <input
                                type="text"
                                name="sort"
                                onChange={(e) => {
                                    this.setState({
                                        add_sort: e.target.value,
                                    });
                                }}
                                value={this.state.add_sort}
                                required="required"
                            />
                            <label htmlFor="visible">Показване</label>
                            <input
                                type="checkbox"
                                name="visible"
                                checked={this.state.add_visible}
                                onChange={() => {
                                    if (this.state.add_visible === "checked") {
                                        this.setState({
                                            add_visible: "",
                                        });
                                    } else {
                                        this.setState({
                                            add_visible: "checked",
                                        });
                                    }
                                }}
                            />
                            <label htmlFor="no_commission">
                                Без комисионна
                            </label>
                            <input
                                type="checkbox"
                                name="no_commission"
                                checked={this.state.add_no_commission}
                                onChange={() => {
                                    if (
                                        this.state.add_no_commission ===
                                        "checked"
                                    ) {
                                        this.setState({
                                            add_no_commission: "",
                                        });
                                    } else {
                                        this.setState({
                                            add_no_commission: "checked",
                                        });
                                    }
                                }}
                            />
                            <label htmlFor="promo">Ексклузивна оферта</label>
                            <input
                                type="checkbox"
                                name="promo"
                                checked={this.state.add_promo}
                                onChange={() => {
                                    if (this.state.add_promo === "checked") {
                                        this.setState({
                                            add_promo: "",
                                        });
                                    } else {
                                        this.setState({
                                            add_promo: "checked",
                                        });
                                    }
                                }}
                            />

                            <label htmlFor="new_offer">Нови оферти</label>
                            <input
                                type="checkbox"
                                name="new_offer"
                                checked={this.state.add_new_offer}
                                onChange={() => {
                                    if (
                                        this.state.add_new_offer === "checked"
                                    ) {
                                        this.setState({
                                            add_new_offer: "",
                                        });
                                    } else {
                                        this.setState({
                                            add_new_offer: "checked",
                                        });
                                    }
                                }}
                            />

                            <label
                                htmlFor="bg_description"
                                style={{ marginBottom: 15 }}
                            >
                                Описание
                            </label>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.setState({
                                        add_bg_description: data,
                                    });
                                }}
                                value={this.state.add_bg_description}
                            />
                            <label
                                htmlFor="en_description"
                                style={{ marginBottom: 15 }}
                            >
                                Description
                            </label>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.setState({
                                        add_en_description: data,
                                    });
                                }}
                                value={this.state.add_bg_description}
                            />

                            <label htmlFor="file">Добави снимки</label>
                            <input
                                type="file"
                                onChange={this.selectImages}
                                multiple
                            />

                            <Map
                                onViewportChanged={this.onViewportChanged}
                                viewport={this.state.viewport}>
                                <TileLayer
                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker 
                                    position={[this.state.latitude, this.state.longitude]}
                                    draggable={true}
                                    onDragend={this.updateMarkerPosition}
                                    ref={this.refmarker}
                                />
                            </Map>

                            <label htmlFor="virtualtour">Връзка към виртуална обиколка</label>
                            <input
                                type="text"
                                name="virtualtour"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        add_virtualtour: e.target.value,
                                    });
                                }}
                                value={this.state.add_virtualtour}
                            />

                            <input type="submit" value="Добави" />
                        </form>
                        <div className="listProperties shadow">
                            <h3>Всички обяви</h3>
                            <br />
                            <br />
                            <input
                                type="text"
                                placeholder="Търси по ID"
                                onChange={(e) => {
                                    this.setState(
                                        {
                                            searchById: Number(e.target.value),
                                            limit_skip: 0,
                                        },
                                        () => {
                                            setTimeout(() => {
                                                this.searchById();
                                            }, 1000);
                                        }
                                    );
                                }}
                            />
                            <br />
                            {this.state.allProperties.length > 0
                                ? this.state.allProperties.map(
                                      (property, id) => {
                                          return (
                                              <div
                                                  className="property"
                                                  key={id}
                                              >
                                                  <font color="black">
                                                      {property.code}
                                                  </font>{" "}
                                                  {property.bg_name},{" "}
                                                  {property.bg_city}
                                                  <button
                                                      onClick={() => {
                                                          this.deleteProperty(
                                                              property.id,
                                                              property.bg_name,
                                                              property.bg_city
                                                          );
                                                      }}
                                                      className="deleteButton"
                                                  >
                                                      Премахни
                                                  </button>
                                                  <Link
                                                      to={
                                                          "/management/property/edit/" +
                                                          property.id
                                                      }
                                                  >
                                                      <button className="editButton">
                                                          Редактирай
                                                      </button>
                                                  </Link>
                                              </div>
                                          );
                                      }
                                  )
                                : ""}

                            <div className="itemPagination">
                                {this.state.allProperties.length > 0 &&
                                this.state.limit_skip > 0 ? (
                                    <button
                                        onClick={this.load20Less}
                                        className="loadLess"
                                    >
                                        -20 имота
                                    </button>
                                ) : (
                                    ""
                                )}
                                {this.state.allProperties.length > 0 && (
                                    <button
                                        onClick={this.load20More}
                                        className="loadMore"
                                    >
                                        +20 имота
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(ManagementPropertiesPage);
