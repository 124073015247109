import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import formatCurrency from "./../helpers/formatCurrency";

class ExclusiveMiniProperty extends Component {
    render() {
        if (this.props.data === undefined) {
            return (<div></div>)
        }
        let placeBgName = this.props.data.placeBgName
            .replace(/област/, "")
            .replace(/град/, "");
        let placeEnName = this.props.data.placeEnName.replace(/district/, "");

        return (
            <Link to={"/property/" + this.props.data.id}>
                <div>
                    <div className="price darkShadow">
                        {this.props.data.currency === "EURO" && "€ "}
                        {formatCurrency(this.props.data.price)}
                    </div>
                    <div className="title">
                        {this.props.lang === "bg" ? placeBgName : placeEnName}
                    </div>
                </div>
            </Link>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang,
    };
};

export default connect(mapStateToProps)(ExclusiveMiniProperty);
