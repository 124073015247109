import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { globalhostapi } from "./../config/variables";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import toastr from "toastr";
import { Link } from "react-router-dom";

import ManagementNav from "./../Components/ManagementNav";

class ManagementBlogPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allPosts: [],
            allPostsBG: [],
            allPostsEN: [],

            title: "",
            description: "",
            sort: 10,
            visible: "checked",
            picture: "",
            language: "",
        };

        this.addPost = this.addPost.bind(this);
        this.deletePost = this.deletePost.bind(this);
    }

    componentDidMount() {
        axios
            .get(globalhostapi + "blog/bg/post/all")
            .then((response) => {
                this.setState({
                    allPostsBG: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        axios
            .get(globalhostapi + "blog/en/post/all")
            .then((response) => {
                this.setState({
                    allPostsEN: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    addPost(e) {
        e.preventDefault();

        const formValues = new FormData();
        formValues.append("title", this.state.title);
        formValues.append("description", this.state.description);
        formValues.append("sort", this.state.sort);
        formValues.append(
            "visible",
            this.state.visible === "checked" ? "Y" : "N"
        );
        formValues.append("picture", this.state.picture);
        formValues.append("author_id", this.props.auth.user.id);

        axios
            .post(
                globalhostapi + "blog/" + this.state.language + "/post/add",
                formValues
            )
            .then(() => {
                axios
                    .get(globalhostapi + "blog/bg/post/all")
                    .then((response) => {
                        this.setState({
                            allPostsBG: response.data,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                axios
                    .get(globalhostapi + "blog/en/post/all")
                    .then((response) => {
                        this.setState({
                            allPostsEN: response.data,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                toastr.success("Успешно създадена новина.");
            })
            .catch((error) => {
                toastr.warning("Неуспешно създаването на новина.");
            });
    }

    deletePost(id, title, language) {
        if (window.confirm("Изтрий " + title + "?")) {
            axios
                .delete(
                    globalhostapi + "blog/" + language + "/post/delete/" + id
                )
                .then(() => {
                    axios
                        .get(globalhostapi + "blog/" + language + "/post/all")
                        .then((response) => {
                            if (language === "bg") {
                                this.setState({
                                    allPostsBG: response.data,
                                });
                            } else {
                                this.setState({
                                    allPostsEN: response.data,
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    toastr.success("Успешно изтрита новина.");
                })
                .catch((err) => {
                    toastr.warning("Неуспешно изтриването на новина.");
                });
        }
    }

    selectImage = (event) => {
        this.setState({ picture: event.target.files[0] });
    };

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <form
                            className="addBlog shadow"
                            onSubmit={this.addPost}
                        >
                            <h3>Добави новина</h3>
                            <br />
                            <label htmlFor="language">Език на новината</label>
                            <select
                                className="shadow"
                                onChange={(e) => {
                                    this.setState({
                                        language: e.target.value,
                                    });
                                }}
                                value={this.state.language}
                                required="required"
                            >
                                <option value="">Избери език</option>
                                <option value="bg">български</option>
                                <option value="en">английски</option>
                            </select>
                            <label htmlFor="title">Заглавие</label>
                            <input
                                type="text"
                                name="title"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        title: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="description">Текст</label>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.setState({
                                        description: data,
                                    });
                                }}
                            />

                            <label htmlFor="sort">Приоритет</label>
                            <input
                                type="text"
                                name="sort"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        sort: e.target.value,
                                    });
                                }}
                                value={this.state.sort}
                                required="required"
                            />

                            <label htmlFor="visible">Показване</label>
                            <input
                                type="checkbox"
                                name="visible"
                                checked={this.state.visible}
                                onChange={() => {
                                    if (this.state.visible === "checked") {
                                        this.setState({
                                            visible: "",
                                        });
                                    } else {
                                        this.setState({
                                            visible: "checked",
                                        });
                                    }
                                }}
                            />

                            <label htmlFor="file">Добави снимка</label>
                            <input type="file" onChange={this.selectImage} />

                            <input type="submit" value="Добави" />
                        </form>
                        <div className="listProperties shadow">
                            <h3>Всички новини</h3>
                            <br />
                            {this.state.allPostsBG.length > 0
                                ? this.state.allPostsBG.map((post, id) => {
                                      return (
                                          <div className="property" key={id}>
                                              <font style={{ color: "#50529e" }}>(bg)</font> {post.title}
                                              <button
                                                  onClick={() => {
                                                      this.deletePost(
                                                          post.id,
                                                          post.title,
                                                          "bg"
                                                      );
                                                  }}
                                                  className="deleteButton"
                                              >
                                                  Премахни
                                              </button>
                                              <Link
                                                  to={
                                                      "/management/post/edit/bg/" +
                                                      post.id
                                                  }
                                              >
                                                  <button className="editButton">
                                                      Редактирай
                                                  </button>
                                              </Link>
                                          </div>
                                      );
                                  })
                                : ""}
                            <br />
                            {this.state.allPostsEN.length > 0
                                ? this.state.allPostsEN.map((post, id) => {
                                      return (
                                          <div className="property" key={id}>
                                              <font style={{ color: "#ed454a" }}>(en)</font> {post.title}
                                              <button
                                                  onClick={() => {
                                                      this.deletePost(
                                                          post.id,
                                                          post.title,
                                                          "en"
                                                      );
                                                  }}
                                                  className="deleteButton"
                                              >
                                                  Премахни
                                              </button>
                                              <Link
                                                  to={
                                                      "/management/post/edit/en/" +
                                                      post.id
                                                  }
                                              >
                                                  <button className="editButton">
                                                      Редактирай
                                                  </button>
                                              </Link>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(ManagementBlogPage);
