import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { globalhosturl, globalhostapi } from "./../config/variables";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Map, TileLayer, Marker } from "react-leaflet-universal";
import toastr from "toastr";

import ManagementNav from "./../Components/ManagementNav";

import "react-datepicker/dist/react-datepicker.css";

class ManagementPropertiesEditPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allSubtypes: [],
            allRegions: [],
            regionPlaces: [],
            allBrokers: [],

            id: "",
            code: "",
            transaction_id: "",
            type_id: "",
            region_id: "",
            place_id: "",
            broker_id: "",
            bg_description: "",
            en_description: "",
            pictures: [],
            new_pictures: [],
            price: "",
            promo_price: "",
            price_type: "",
            ask_for_price: "",
            square: "",
            floor: "",
            no_commission: "",
            promo: "",
            new_offer: "",
            status: "",
            sort: "",
            visible: "checked",
            virtualtour: "",
            viewport: {
                center: [43.0776856, 25.6282174],
                zoom: 16,
            },
            latitude: 43.0776856,
            longitude: 25.6282174,
        };

        this.editProperty = this.editProperty.bind(this);
        this.deletePropertyPicture = this.deletePropertyPicture.bind(this);
        this.onViewportChanged = this.onViewportChanged.bind(this);
        this.updateMarkerPosition = this.updateMarkerPosition.bind(this);
    }

    componentDidMount() {
        axios
            .get(globalhostapi + "property/one/" + this.props.match.params.id)
            .then((response) => {
                this.setState(
                    {
                        id: response.data.id,
                        code: response.data.code,
                        transaction_id: response.data.transaction_id,
                        type_id: response.data.type_id,
                        region_id: response.data.regionId,
                        place_id: response.data.place_id,
                        broker_id: response.data.broker_id,
                        bg_description:
                            response.data.bg_description !== null
                                ? response.data.bg_description
                                : "",
                        en_description:
                            response.data.en_description !== null
                                ? response.data.en_description
                                : "",
                        pictures: response.data.pictures,
                        price: response.data.price,
                        promo_price: response.data.promo_price,
                        price_type: response.data.price_type,
                        ask_for_price:
                            response.data.ask_for_price === "Y"
                                ? "checked"
                                : "",
                        square: response.data.square,
                        floor: response.data.floor,
                        no_commission:
                            response.data.no_commission === "Y"
                                ? "checked"
                                : "",
                        promo: response.data.promo === "Y" ? "checked" : "",
                        new_offer:
                            response.data.new_offer === "Y" ? "checked" : "",
                        status: response.data.status,
                        sort: response.data.sort,
                        visible: response.data.visible === "Y" ? "checked" : "",
                        virtualtour: response.data.virtualtour,
                        viewport: {
                            center: [
                                response.data.latitude,
                                response.data.longitude,
                            ],
                            zoom: response.data.zoom,
                        },
                        latitude: response.data.latitude,
                        longitude: response.data.longitude,
                    },
                    () => {
                        axios
                            .get(globalhostapi + "subtype/all")
                            .then((response1) => {
                                this.setState({ allSubtypes: response1.data });
                            })
                            .catch(function (error) {
                                console.log(error);
                            });

                        axios
                            .get(globalhostapi + "region/all")
                            .then((response2) => {
                                this.setState(
                                    {
                                        allRegions: response2.data,
                                    },
                                    () => {
                                        axios
                                            .get(
                                                globalhostapi +
                                                    "region/" +
                                                    this.state.region_id +
                                                    "/places"
                                            )
                                            .then((response3) => {
                                                this.setState({
                                                    regionPlaces:
                                                        response3.data,
                                                });
                                            })
                                            .catch(function (error) {
                                                console.log(error);
                                            });
                                    }
                                );
                            })
                            .catch(function (error) {
                                console.log(error);
                            });

                        axios
                            .get(globalhostapi + "broker/all")
                            .then((response4) => {
                                this.setState({ allBrokers: response4.data });
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                );
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    editProperty(e) {
        e.preventDefault();

        const formData = new FormData();

        formData.append("id", this.state.id);
        formData.append("code", this.state.code);
        formData.append("transaction_id", this.state.transaction_id);
        formData.append("type_id", this.state.type_id);
        formData.append("place_id", this.state.place_id);
        formData.append("broker_id", this.state.broker_id);
        formData.append("bg_description", this.state.bg_description);
        formData.append("en_description", this.state.en_description);
        formData.append("price", this.state.price);
        formData.append("promo_price", this.state.promo_price);
        formData.append("price_type", this.state.price_type);
        formData.append(
            "ask_for_price",
            this.state.ask_for_price === "checked" ? "Y" : "N"
        );
        formData.append("square", this.state.square);
        formData.append("floor", this.state.floor);
        formData.append(
            "no_commission",
            this.state.no_commission === "checked" ? "Y" : "N"
        );
        formData.append("promo", this.state.promo === "checked" ? "Y" : "N");
        formData.append(
            "new_offer",
            this.state.new_offer === "checked" ? "Y" : "N"
        );
        formData.append("status", this.state.status);
        formData.append("sort", this.state.sort);
        formData.append(
            "visible",
            this.state.visible === "checked" ? "Y" : "N"
        );
        formData.append("virtualtour", this.state.virtualtour);
        formData.append("latitude", this.state.latitude);
        formData.append("longitude", this.state.longitude);
        formData.append("zoom", this.state.viewport.zoom);

        formData.append("token", this.props.auth.user.token);
        formData.append(
            "token_valid_until",
            this.props.auth.user.token_valid_until
        );

        axios
            .post(globalhostapi + "property/edit", formData)
            .then((response1) => {
                if (typeof response1.data === "object") {
                    if (response1.data.id) {
                        for (let picture of this.state.new_pictures) {
                            const pictureData = new FormData();

                            pictureData.append("id", response1.data.id);
                            pictureData.append("picture", picture);
                            pictureData.append(
                                "token",
                                this.props.auth.user.token
                            );
                            pictureData.append(
                                "token_valid_until",
                                this.props.auth.user.token_valid_until
                            );

                            axios
                                .post(
                                    globalhostapi + "property/edit/pictures",
                                    pictureData
                                )
                                .then(() => {
                                    axios
                                        .get(
                                            globalhostapi +
                                                "property/one/" +
                                                this.props.match.params.id
                                        )
                                        .then((response2) => {
                                            this.setState({
                                                pictures:
                                                    response2.data.pictures,
                                                new_pictures: [],
                                            });
                                            toastr.success(
                                                "Успешно добавена снимка."
                                            );
                                        })
                                        .catch((error) => console.error(error));
                                })
                                .catch((err) => console.log(err));
                        }
                        toastr.success("Успешно редактирана обява.");
                    } else if (
                        response1.data.id === undefined ||
                        response1.data.id === null
                    ) {
                        Object.keys(response1.data).map((error, i) =>
                            toastr.warning("Грешка: " + JSON.stringify(error))
                        );
                    }
                }
            })
            .catch((error) => console.error(error));
    }

    previewPropertyPictures() {
        if (
            this.state.pictures !== undefined &&
            this.state.pictures !== null &&
            this.state.pictures.length > 2
        ) {
            let pictures = this.state.pictures.split(",");

            return pictures.map((picture, i) => {
                let url = picture.replace(/[^A-Za-z0-9./\-:]/g, "");
                let newUrl = url
                    .replace("public", "storage")
                    .replace("/data/image", "storage/images");
                let onlyName = newUrl.split("/");

                return (
                    <div className="propertyPicture">
                        <img
                            src={globalhosturl + newUrl}
                            alt=""
                            key={i}
                            style={{ width: "100%", height: "100%" }}
                        />
                        <button
                            type="button"
                            className="deletePropertyPicture"
                            onClick={() =>
                                this.deletePropertyPicture(
                                    this.props.match.params.id,
                                    onlyName[onlyName.length - 1]
                                )
                            }
                        >
                            Изтрий
                        </button>
                    </div>
                );
            });
        }
    }

    selectImages = (event) => {
        let images = [];
        for (var i = 0; i < event.target.files.length; i++) {
            images[i] = event.target.files.item(i);
        }
        images = images.filter((image) =>
            image.name.match(/\.(jpg|jpeg|png)$/)
        );
        this.setState({ new_pictures: images });
    };

    deletePropertyPicture(propertyId, pictureName) {
        if (window.confirm("Изтрий снимката?")) {
            axios
                .post(globalhostapi + "property/delete/picture", {
                    property_id: propertyId,
                    picture_name: pictureName,
                    token: this.props.auth.user.token,
                    token_valid_until: this.props.auth.user.token_valid_until,
                })
                .then(() => {
                    axios
                        .get(
                            globalhostapi +
                                "property/one/" +
                                this.props.match.params.id
                        )
                        .then((response) => {
                            this.setState({
                                pictures: response.data.pictures,
                            });
                        })
                        .catch((error) => console.error(error));

                    toastr.success("Успешно изтрита снимка.");
                })
                .catch(() =>
                    toastr.warning("Неуспешно изтриването на снимка.")
                );
        }
    }

    onViewportChanged = (viewport) => {
        this.setState({ viewport });
    };

    refmarker = createRef();

    updateMarkerPosition = (e) => {
        let lat = e.target._latlng.lat;
        let lng = e.target._latlng.lng;
        this.setState({
            latitude: lat,
            longitude: lng,
        });
    };

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <form
                            className="addProperty shadow"
                            onSubmit={this.editProperty}
                            encType="multipart/form-data"
                        >
                            <h3>Редактирай обява</h3>
                            <br />
                            <label htmlFor="code">Код (ID)</label>
                            <input
                                type="text"
                                name="code"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        code: e.target.value,
                                    });
                                }}
                                value={this.state.code}
                            />
                            <label htmlFor="type_offer">Вид обява</label>
                            <select
                                name="type_offer"
                                onChange={(e) => {
                                    this.setState({
                                        transaction_id: e.target.value,
                                    });
                                }}
                                value={this.state.transaction_id}
                                required="required"
                            >
                                <option value="">Избери</option>
                                <option value="1">Продажби</option>
                                <option value="2">Наеми</option>
                            </select>
                            <label htmlFor="type_id">Тип на имота</label>
                            <select
                                name="type_id"
                                onChange={(e) => {
                                    this.setState({
                                        type_id: e.target.value,
                                    });
                                }}
                                value={this.state.type_id}
                                required="required"
                            >
                                <option value="">Избери</option>
                                {this.state.allSubtypes.length > 0
                                    ? this.state.allSubtypes.map(
                                          (subtype, id) => {
                                              return (
                                                  <option
                                                      value={subtype.id}
                                                      key={id}
                                                  >
                                                      {subtype.bg_name}
                                                  </option>
                                              );
                                          }
                                      )
                                    : ""}
                            </select>
                            <label htmlFor="region_id">Населено място</label>
                            <select
                                name="region_id"
                                onChange={(e) => {
                                    this.setState(
                                        {
                                            region_id: e.target.value,
                                        },
                                        () => {
                                            axios
                                                .get(
                                                    globalhostapi +
                                                        "region/" +
                                                        this.state.region_id +
                                                        "/places"
                                                )
                                                .then((response) => {
                                                    this.setState({
                                                        regionPlaces:
                                                            response.data,
                                                    });
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                });
                                        }
                                    );
                                }}
                                value={this.state.region_id}
                                required="required"
                            >
                                <option value="">Избери</option>
                                {this.state.allRegions.length > 0
                                    ? this.state.allRegions.map(
                                          (region, id) => {
                                              return (
                                                  <option
                                                      value={region.id}
                                                      key={id}
                                                  >
                                                      {region.bg_city}
                                                  </option>
                                              );
                                          }
                                      )
                                    : ""}
                            </select>
                            <label htmlFor="place_id">Местоположение</label>
                            <select
                                name="place_id"
                                onChange={(e) => {
                                    this.setState({
                                        place_id: e.target.value,
                                    });
                                }}
                                value={this.state.place_id}
                                required="required"
                            >
                                <option value="">Избери</option>
                                {this.state.regionPlaces.length > 0
                                    ? this.state.regionPlaces.map(
                                          (place, id) => {
                                              return (
                                                  <option
                                                      value={place.id}
                                                      key={id}
                                                  >
                                                      {place.bg_city}
                                                  </option>
                                              );
                                          }
                                      )
                                    : ""}
                            </select>
                            <label htmlFor="broker_id">Брокер</label>
                            <select
                                name="broker_id"
                                onChange={(e) => {
                                    this.setState({
                                        broker_id: e.target.value,
                                    });
                                }}
                                value={this.state.broker_id}
                                required="required"
                            >
                                <option value="">Избери</option>
                                {this.state.allBrokers.length > 0
                                    ? this.state.allBrokers.map(
                                          (broker, id) => {
                                              return (
                                                  <option
                                                      value={broker.code}
                                                      key={id}
                                                  >
                                                      {broker.bg_names} (
                                                      {broker.code})
                                                  </option>
                                              );
                                          }
                                      )
                                    : ""}
                            </select>
                            <label htmlFor="status">Статус</label>
                            <select
                                name="status"
                                onChange={(e) => {
                                    this.setState({
                                        status: e.target.value,
                                    });
                                }}
                                value={this.state.status}
                                required="required"
                            >
                                <option value="">Избери</option>
                                <option value="0">Актуален</option>
                                <option value="1">Предложен</option>
                                <option value="2">Продаден/Отдаден</option>
                            </select>
                            <label htmlFor="price">Цена</label>
                            <input
                                type="text"
                                name="price"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        price: e.target.value,
                                    });
                                }}
                                value={this.state.price}
                                required="required"
                            />
                            <label htmlFor="promo_price">Промо цена</label>
                            <input
                                type="text"
                                name="promo_price"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        promo_price: e.target.value,
                                    });
                                }}
                                value={this.state.promo_price}
                            />
                            <select
                                name="price_type"
                                onChange={(e) => {
                                    this.setState({
                                        price_type: e.target.value,
                                    });
                                }}
                                value={this.state.price_type}
                                required="required"
                            >
                                <option value="">Избери</option>
                                <option value="0">€</option>
                                <option value="1">€ / m2</option>
                                <option value="2">€ / месец</option>
                                <option value="3">от €</option>
                                <option value="4">от € / m2</option>
                                <option value="5">от € / месец</option>
                                <option value="6">лв.</option>
                                <option value="7">/ m2</option>
                                <option value="8">/ месец</option>
                                <option value="9">от лв.</option>
                                <option value="10">от / m2</option>
                                <option value="11">от / месец</option>
                            </select>
                            <label htmlFor="square">Площ</label>
                            <input
                                type="text"
                                name="square"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        square: e.target.value,
                                    });
                                }}
                                value={this.state.square}
                                required="required"
                            />
                            <label htmlFor="floor">Етаж</label>
                            <input
                                type="text"
                                name="floor"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        floor: e.target.value,
                                    });
                                }}
                                value={this.state.floor}
                                required="required"
                            />
                            <label htmlFor="sort">Приоритет</label>
                            <input
                                type="text"
                                name="sort"
                                onChange={(e) => {
                                    this.setState({
                                        sort: e.target.value,
                                    });
                                }}
                                value={this.state.sort}
                                required="required"
                            />
                            <label htmlFor="visible">Показване</label>
                            <input
                                type="checkbox"
                                name="visible"
                                checked={this.state.visible}
                                onChange={() => {
                                    if (this.state.visible === "checked") {
                                        this.setState({
                                            visible: "",
                                        });
                                    } else {
                                        this.setState({
                                            visible: "checked",
                                        });
                                    }
                                }}
                            />
                            <label htmlFor="no_commission">
                                Без комисионна
                            </label>
                            <input
                                type="checkbox"
                                name="no_commission"
                                checked={this.state.no_commission}
                                onChange={() => {
                                    if (
                                        this.state.no_commission === "checked"
                                    ) {
                                        this.setState({
                                            no_commission: "",
                                        });
                                    } else {
                                        this.setState({
                                            no_commission: "checked",
                                        });
                                    }
                                }}
                            />
                            <label htmlFor="promo">Ексклузивна оферта</label>
                            <input
                                type="checkbox"
                                name="promo"
                                checked={this.state.promo}
                                onChange={() => {
                                    if (this.state.promo === "checked") {
                                        this.setState({
                                            promo: "",
                                        });
                                    } else {
                                        this.setState({
                                            promo: "checked",
                                        });
                                    }
                                }}
                            />

                            <label htmlFor="new_offer">Нови оферти</label>
                            <input
                                type="checkbox"
                                name="new_offer"
                                checked={this.state.new_offer}
                                onChange={() => {
                                    if (this.state.new_offer === "checked") {
                                        this.setState({
                                            new_offer: "",
                                        });
                                    } else {
                                        this.setState({
                                            new_offer: "checked",
                                        });
                                    }
                                }}
                            />
                            <label htmlFor="ask_for_price">
                                Цена при запитване
                            </label>
                            <input
                                type="checkbox"
                                name="ask_for_price"
                                checked={this.state.ask_for_price}
                                onChange={() => {
                                    if (
                                        this.state.ask_for_price === "checked"
                                    ) {
                                        this.setState({
                                            ask_for_price: "",
                                        });
                                    } else {
                                        this.setState({
                                            ask_for_price: "checked",
                                        });
                                    }
                                }}
                            />

                            <label
                                htmlFor="bg_description"
                                style={{ marginBottom: 15 }}
                            >
                                Описание
                            </label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={this.state.bg_description}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.setState({
                                        bg_description: data,
                                    });
                                }}
                                value={this.state.bg_description}
                            />
                            <label
                                htmlFor="en_description"
                                style={{ marginBottom: 15 }}
                            >
                                Description
                            </label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={this.state.en_description}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.setState({
                                        en_description: data,
                                    });
                                }}
                                value={this.state.bg_description}
                            />

                            <div>
                                <label htmlFor="pictures">Снимки</label>
                                {this.previewPropertyPictures()}
                            </div>

                            <label htmlFor="file">Добави снимки</label>
                            <input
                                type="file"
                                ref={this.state.new_pictures}
                                onChange={this.selectImages}
                                multiple
                            />

                            <label htmlFor="map">Локация на имота</label>
                            <Map
                                onViewportChanged={this.onViewportChanged}
                                viewport={this.state.viewport}
                            >
                                <TileLayer
                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker
                                    position={[
                                        this.state.latitude,
                                        this.state.longitude,
                                    ]}
                                    draggable={true}
                                    onDragend={this.updateMarkerPosition}
                                    ref={this.refmarker}
                                />
                            </Map>

                            <label htmlFor="virtualtour">
                                Връзка към виртуална обиколка
                            </label>
                            <input
                                type="text"
                                name="virtualtour"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        virtualtour: e.target.value,
                                    });
                                }}
                                value={this.state.virtualtour}
                            />

                            <input type="submit" value="Редактирай" />
                        </form>
                        <div className="listProperties shadow"></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(ManagementPropertiesEditPage);
