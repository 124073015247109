export default function formatDate(date, lang) {
    
    let getDate = date.split("T")[0];
    let getYear = getDate.split("-")[0];
    let getMonth = getDate.split("-")[1];
    let monthName = "";
    let getDay = getDate.split("-")[2];

    switch (getMonth) {
        case "01":
            monthName = lang === "bg"? "Януари" : "January";
            break;
        case "02":
            monthName = lang ==="bg"? "Февруари" : "February";
            break;
        case "03":
            monthName = lang ==="bg"? "Март" : "March";
            break;
        case "04":
            monthName = lang ==="bg"? "Април" : "April";
            break;
        case "05":
            monthName = lang ==="bg"? "Май" : "May";
            break;
        case "06":
            monthName = lang ==="bg"? "Юни" : "June";
            break;
        case "07":
            monthName = lang ==="bg"? "Юли" : "July";
            break;
        case "08":
            monthName = lang ==="bg"? "Август" : "August";
            break;
        case "09":
            monthName = lang ==="bg"? "Септември" : "September";
            break;
        case "10":
            monthName = lang ==="bg"? "Октомври" : "October";
            break;
        case "11":
            monthName = lang ==="bg"? "Ноември" : "November";
            break;
        case "12":
            monthName = lang ==="bg"? "Декември" : "December";
            break;
        default:
            break;
    }

    return getDay + " " + monthName + ", " + getYear;
}