const HOST = 'http://127.0.0.1:8000/api'

export const FETCH_PROPERTIES = 'FETCH_PROPERTIES'
export const FETCH_REGIONS = 'FETCH_REGIONS'
export const FETCH_PLACES = 'FETCH_PLACES'
export const FETCH_TYPES = 'FETCH_TYPES'
export const FETCH_SUBTYPES = 'FETCH_SUBTYPES'
export const FETCH_BROKERS = 'FETCH_BROKERS'

export const actionFetchProperties = () => {

    return dispatch => {

        fetchAllProperties()
            .then(response => response.json())
            .then(data => {

                dispatch({ 
                    type: FETCH_PROPERTIES,
                    properties: data
                })
                
                localStorage.setItem('properties', data)
            })
            .catch(error => console.log(error))
    }
}

async function fetchAllProperties() {
    return await fetch(HOST + '/property/all')
}

export const actionFetchRegions = () => {

    return dispatch => {

        fetchAllRegions()
            .then(response => response.json())
            .then(data => {

                dispatch({ 
                    type: FETCH_REGIONS,
                    regions: data
                })
                
                localStorage.setItem('regions', data)
            })
            .catch(error => console.log(error))
    }
}

async function fetchAllRegions() {
    return await fetch(HOST + '/region/all')
}

export const actionFetchPlaces = () => {

    return dispatch => {

        fetchAllPlaces()
            .then(response => response.json())
            .then(data => {

                dispatch({ 
                    type: FETCH_PLACES,
                    places: data
                })
                
                localStorage.setItem('places', data)
            })
            .catch(error => console.log(error))
    }
}

async function fetchAllPlaces() {
    return await fetch(HOST + '/place/all')
}

export const actionFetchTypes = () => {

    return dispatch => {

        fetchAllTypes()
            .then(response => response.json())
            .then(data => {

                dispatch({ 
                    type: FETCH_TYPES,
                    types: data
                })
                
                localStorage.setItem('types', data)
            })
            .catch(error => console.log(error))
    }
}

async function fetchAllTypes() {
    return await fetch(HOST + '/type/all')
}

export const actionFetchSubtypes = () => {

    return dispatch => {

        fetchAllSubtypes()
            .then(response => response.json())
            .then(data => {

                dispatch({ 
                    type: FETCH_SUBTYPES,
                    subtypes: data
                })
                
                localStorage.setItem('subtypes', data)
            })
            .catch(error => console.log(error))
    }
}

async function fetchAllSubtypes() {
    return await fetch(HOST + '/subtype/all')
}

export const actionFetchBrokers = () => {

    return dispatch => {

        fetchAllBrokers()
            .then(response => response.json())
            .then(data => {

                dispatch({ 
                    type: FETCH_BROKERS,
                    brokers: data
                })
                
                localStorage.setItem('brokers', data)
            })
            .catch(error => console.log(error))
    }
}

async function fetchAllBrokers() {
    return await fetch(HOST + '/broker/all')
}