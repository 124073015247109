export const i18bg = {
    title: "Новини",
    subtitle: "Бъдете в течение с всички новини и събития.",
    allnews: "Всички новини"
}

export const i18en = {
    title: "Blog",
    subtitle: "Keep up to date with all the latest news and events.",
    allnews: "All blog posts"
}