import React, { Component } from "react";
import axios from "axios";
import { globalhostapi } from "./../config/variables"
import toastr from "toastr";
import ManagementNav from "./../Components/ManagementNav"

class ManagementRegionsCreatePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bg_city: "",
            en_city: "",
            visible: "checked"
        };

        this.addRegion = this.addRegion.bind(this);
    }

    addRegion() {
        axios.post(globalhostapi + "region/create", {
                bg_city: this.state.bg_city,
                en_city: this.state.en_city,
                visible: this.state.visible === "checked" ? "Y" : "N"
            })
            .then(() => {
                toastr.success("Успешно добавен регион.")
            })
            .catch(() => {
                toastr.success("Неуспешно добавянето на регион.")
            });
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="addRegion shadow">
                        <h3>Добави регион</h3>
                            <br />
                            <label htmlFor="bg_city">Име на български</label>
                            <input
                                type="text"
                                name="bg_city"
                                placeholder=""
                                onChange={e => {
                                    this.setState({
                                        bg_city: e.target.value
                                    });
                                }}
                                value={this.state.bg_city}
                                required="required"
                            />
                            <label htmlFor="en_city">Име на английски</label>
                            <input
                                type="text"
                                name="en_city"
                                placeholder=""
                                onChange={e => {
                                    this.setState({
                                        en_city: e.target.value
                                    });
                                }}
                                value={this.state.en_city}
                                required="required"
                            />
                            <label htmlFor="visible">Показване</label>
                            <input
                                type="checkbox"
                                name="visible"
                                checked={this.state.visible}
                                onChange={() => {
                                    if (this.state.visible === "checked") {
                                        this.setState({
                                            visible: ""
                                        });
                                    } else {
                                        this.setState({
                                            visible: "checked"
                                        });
                                    }
                                }}
                            />
                            <input type="button" onClick={this.addRegion} value="Добави" />
                        </div>
                        <div className="listProperties shadow">
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementRegionsCreatePage;
