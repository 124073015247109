import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import axios from "axios";
import { globalhostapi } from "../config/variables";

class PropertyManagementPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title_bg: "",
            description_bg: "",
            title_en: "",
            description_en: "",
        };
    }

    componentDidMount() {
        axios
            .get(globalhostapi + "pages/one/6")
            .then((response) => {
                this.setState({
                    title_bg: response.data.title_bg,
                    description_bg: response.data.description_bg,
                    title_en: response.data.title_en,
                    description_en: response.data.description_en,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div className="block s-fs">
                <Helmet>
                    <title>
                        {this.props.lang === "bg"
                            ? process.env.REACT_APP_title_PropertyManagementPage_bg
                            : process.env.REACT_APP_title_PropertyManagementPage_en}
                        {" | "}
                        {this.props.lang === "bg"
                            ? process.env.REACT_APP_title_CompanyName_bg
                            : process.env.REACT_APP_title_CompanyName_en}
                    </title>
                </Helmet>
                <div className="block s-md">
                    <h1 className="rentTitle">
                        {this.props.lang === "bg"
                            ? this.state.title_bg
                            : this.state.title_en}
                    </h1>
                    <div
                        className="servicesContainer shadow"
                        dangerouslySetInnerHTML={{
                            __html:
                                this.props.lang === "bg"
                                    ? this.state.description_bg
                                    : this.state.description_en,
                        }}
                    ></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang,
    };
};

export default connect(mapStateToProps)(PropertyManagementPage);
