export default function formatCurrency(amount) {
    return Number(Math.round(amount)).toLocaleString("bg-BG", {
        minimumFractionDigits: 2,
    });
}

export function formatCurrencyBGN(amount) {
    return Number(Math.round(amount)).toLocaleString("bg-BG", {
        maximumFractionDigits: 2,
    });
}
