import {
    FETCH_PROPERTIES,
    FETCH_REGIONS,
    FETCH_PLACES,
    FETCH_TYPES,
    FETCH_SUBTYPES,
    FETCH_BROKERS,
} from './../actions/properties'

let initialState = {
    properties: [],
    regions: [],
    places: [],
    types: [],
    subtypes: [],
    brokers: [],
}

export function properties(state = initialState, action) {

    switch (action.type) {

        case FETCH_PROPERTIES:

            return Object.assign({}, state, {
                properties: action.properties
            })

        case FETCH_REGIONS:

            return Object.assign({}, state, {
                regions: action.regions
            })

        case FETCH_PLACES:

            return Object.assign({}, state, {
                places: action.places
            })

        case FETCH_TYPES:

            return Object.assign({}, state, {
                types: action.types
            })

        case FETCH_SUBTYPES:

            return Object.assign({}, state, {
                subtypes: action.subtypes
            })

        case FETCH_BROKERS:

            return Object.assign({}, state, {
                brokers: action.brokers
            })

        default:

            return initialState
    }
}