import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { globalhostapi } from "./../config/variables"
import toastr from "toastr";
import { Link } from "react-router-dom";

import ManagementNav from "./../Components/ManagementNav";

class ManagementUsersPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allUsers: [],

            register_names: "",
            register_email: "",
            register_usr_name: "",
            register_usr_pass: "",
            register_active: "checked",
        };

        this.addUser = this.addUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentDidMount() {
        axios
            .get(globalhostapi + "user/all")
            .then((response) => {
                this.setState({
                    allUsers: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    addUser(e) {
        e.preventDefault();

        const formValues = new FormData();
        formValues.append("register_names", this.state.register_names);
        formValues.append("register_email", this.state.register_email);
        formValues.append("register_usr_name", this.state.register_usr_name);
        formValues.append("register_usr_pass", this.state.register_usr_pass);
        formValues.append(
            "register_active",
            this.state.register_active === "checked" ? "Y" : "N"
        );

        formValues.append("token", this.props.auth.user.token)
        formValues.append("token_valid_until", this.props.auth.user.token_valid_until)

        axios
            .post(globalhostapi + "user/add", formValues)
            .then(() => {
                axios
                    .get(globalhostapi + "user/all")
                    .then((response) => {
                        this.setState({
                            allUsers: response.data,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                toastr.success("Успешно създаден потребител.");
            })
            .catch((error) => {
                toastr.warning("Неуспешно създаването на потребител.");
            });
    }

    deleteUser(id, names) {
        if (window.confirm("Изтрий " + names + "?")) {
            axios
                .post(globalhostapi + "user/delete/" + id, {
                    token: this.props.auth.user.token,
                    token_valid_until: this.props.auth.user.token_valid_until
                })
                .then(() => {
                    axios
                        .get(globalhostapi + "user/all")
                        .then((response) => {
                            this.setState({
                                allUsers: response.data,
                            });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    toastr.success("Успешно изтрит потребител.");
                })
                .catch((err) => {
                    toastr.warning("Неуспешно изтриването на потребител.");
                });
        }
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <form
                            className="addUser shadow"
                            onSubmit={this.addUser}
                        >
                            <h3>Добави потребител</h3>
                            <br />
                            <label htmlFor="names">Име на потребителя*</label>
                            <input
                                type="text"
                                name="names"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        register_names: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="email">E-mail адрес*</label>
                            <input
                                type="text"
                                name="email"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        register_email: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="usr_name">Потребителско име*</label>
                            <input
                                type="text"
                                name="usr_name"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        register_usr_name: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="usr_pass">
                                Потребителска парола*
                            </label>
                            <input
                                type="password"
                                name="usr_pass"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        register_usr_pass: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="active">Статус</label>
                            <input
                                type="checkbox"
                                name="active"
                                checked={this.state.register_active}
                                onChange={() => {
                                    if (
                                        this.state.register_active === "checked"
                                    ) {
                                        this.setState({
                                            register_active: "",
                                        });
                                    } else {
                                        this.setState({
                                            register_active: "checked",
                                        });
                                    }
                                }}
                            />
                            <input type="submit" value="Добави" />
                        </form>
                        <div className="listProperties shadow">
                            <h3>Всички потребители</h3>
                            <br />
                            {this.state.allUsers.length > 0
                                ? this.state.allUsers.map((user, id) => {
                                      return (
                                          <div className="property" key={id}>
                                              {user.names}

                                              <button
                                                  onClick={() => {
                                                      this.deleteUser(
                                                          user.id,
                                                          user.names
                                                      );
                                                  }}
                                                  className="deleteButton"
                                              >
                                                  Премахни
                                              </button>

                                              <Link
                                                  to={
                                                      "/management/user/edit/" +
                                                      user.id
                                                  }
                                              >
                                                  <button className="editButton">
                                                      Редактирай
                                                  </button>
                                              </Link>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(ManagementUsersPage);
