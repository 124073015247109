import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { i18bg, i18en } from "./../locale/footer";

// import LOGO_BG from "./../images/logo_rebranded_20_years_anni/rsz_logo_bg.png";
import LOGO_BG from "./../images/logo_rebranded_20_years_anni/logo_bg.svg";
// import LOGO_EN from "./../images/logo_rebranded_20_years_anni/rsz_logo_en.png";
import LOGO_EN from "./../images/logo_rebranded_20_years_anni/logo_en.svg";

import twitterLogo from "./../images/social/twitter.png";
import facebookLogo from "./../images/social/facebook.png";
import instagramLogo from "./../images/social/instagram.png";

import footerImage from "./../images/footer.jpg";

class Footer extends Component {
    render() {
        return (
            <div className="block s-fs" style={{ backgroundColor: "white" }}>
                <div
                    className="block s-md"
                    style={{
                        backgroundImage: `url(${footerImage})`,
                        backgroundSize: "100%",
                        backgroundPosition: "bottom center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <div
                        className="footer"
                        style={{
                            backgroundImage:
                                this.props.lang === "bg"
                                    ? `url(${LOGO_BG})`
                                    : `url(${LOGO_EN})`,
                        }}
                    >
                        <div className="topItem">
                            <div className="item"></div>
                            <div className="item_logo"></div>
                            <div className="item"></div>
                        </div>
                        <div className="middleItem">
                            <div className="item">
                                <NavLink to="">
                                    {this.props.lang === "bg"
                                        ? i18bg.policy
                                        : i18en.policy}
                                </NavLink>
                                <NavLink to="">
                                    {this.props.lang === "bg"
                                        ? i18bg.toc
                                        : i18en.toc}
                                </NavLink>
                                <NavLink to="/about-us">
                                    {this.props.lang === "bg"
                                        ? i18bg.aboutus
                                        : i18en.aboutus}
                                </NavLink>
                            </div>
                            <div className="item_social">
                                <ul>
                                    <li>
                                        <center>
                                            <img
                                                src={twitterLogo}
                                                alt="Twitter logo"
                                                className="shadow"
                                            />
                                        </center>
                                    </li>
                                    <li>
                                        <center>
                                            <a href="https://www.facebook.com/Balkan-Estate-real-estate-agency-189452227783563">
                                                <img
                                                    src={facebookLogo}
                                                    alt="Facebook logo"
                                                    className="shadow"
                                                />
                                            </a>
                                        </center>
                                    </li>
                                    <li>
                                        <center>
                                            <img
                                                src={instagramLogo}
                                                alt="Instagram logo"
                                                className="shadow"
                                            />
                                        </center>
                                    </li>
                                </ul>
                            </div>
                            <div className="item">
                                <NavLink to="">
                                    {this.props.lang === "bg"
                                        ? i18bg.faq
                                        : i18en.faq}
                                </NavLink>
                                <NavLink to="">
                                    {this.props.lang === "bg"
                                        ? i18bg.policy
                                        : i18en.policy}
                                </NavLink>
                                <NavLink to="/services">
                                    {this.props.lang === "bg"
                                        ? i18bg.services
                                        : i18en.services}
                                </NavLink>
                            </div>
                        </div>
                        <div className="bottomItem">
                            {/* <form className="">
                                <input
                                    type="text"
                                    placeholder="name@mail.com"
                                    className="shadow"
                                />
                                <input
                                    type="submit"
                                    value={
                                        this.props.lang === "bg"
                                            ? i18bg.subscribe
                                            : i18en.subscribe
                                    }
                                    className="shadow"
                                />
                            </form> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang,
    };
};

export default connect(mapStateToProps)(Footer);
