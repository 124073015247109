import React, { Component } from "react";
import axios from "axios";
import { globalhostapi } from "./../config/variables"
import toastr from "toastr";
import ManagementNav from "../Components/ManagementNav";

class ManagementPlacesCreatePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allRegions: [],

            bg_city: "",
            en_city: "",
            c_id: 0,
            visible: "checked",
        };

        this.addPlace = this.addPlace.bind(this);
    }

    componentDidMount() {
        axios
            .get(globalhostapi + "region/all")
            .then((response) => {
                this.setState({
                    allRegions: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    addPlace() {
        axios
            .post(globalhostapi + "place/create", {
                bg_city: this.state.bg_city,
                en_city: this.state.en_city,
                c_id: this.state.c_id,
                sort: this.state.sort,
                visible: this.state.visible === "checked" ? "Y" : "N",
            })
            .then(() => {
                toastr.success("Успешно добавен субрегион.");
            })
            .catch(() => {
                toastr.warning("Неуспешно добавянето на субрегион.");
            });
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="addRegion shadow">
                            <h3>Добави субрегион</h3>
                            <br />
                            <label htmlFor="bg_city">Име на български</label>
                            <input
                                type="text"
                                name="bg_city"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        bg_city: e.target.value,
                                    });
                                }}
                                value={this.state.bg_city}
                                required="required"
                            />
                            <label htmlFor="en_city">Име на английски</label>
                            <input
                                type="text"
                                name="en_city"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        en_city: e.target.value,
                                    });
                                }}
                                value={this.state.en_city}
                                required="required"
                            />
                            <label htmlFor="c_id">Регион</label>
                            <select
                                name="c_id"
                                onChange={(e) => {
                                    this.setState({
                                        c_id: e.target.value,
                                    });
                                }}
                                required="required"
                            >
                                {this.state.allRegions.length > 0
                                    ? this.state.allRegions.map(
                                          (region, id) => {
                                              return (
                                                  <option
                                                      value={region.id}
                                                      key={id}
                                                  >
                                                      {region.bg_city}
                                                  </option>
                                              );
                                          }
                                      )
                                    : ""}
                            </select>
                            <label htmlFor="visible">Показване</label>
                            <input
                                type="checkbox"
                                name="visible"
                                checked={this.state.visible}
                                onChange={() => {
                                    if (this.state.visible === "checked") {
                                        this.setState({
                                            visible: "",
                                        });
                                    } else {
                                        this.setState({
                                            visible: "checked",
                                        });
                                    }
                                }}
                            />
                            <input
                                type="button"
                                onClick={this.addPlace}
                                value="Добави"
                            />
                        </div>
                        <div className="listProperties shadow"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementPlacesCreatePage;
