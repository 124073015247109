import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { i18bg, i18en } from "./../locale/header";

// import LOGO_BG from "./../images/logo_rebranded_20_years_anni/rsz_logo_bg.png";
import LOGO_BG from "./../images/logo_rebranded_20_years_anni/logo_bg.svg";
// import LOGO_EN from "./../images/logo_rebranded_20_years_anni/rsz_logo_en.png";
import LOGO_EN from "./../images/logo_rebranded_20_years_anni/logo_en.svg";

import menuIcon from "./../images/menu.svg";
import closeMenuIcon from "./../images/close.svg";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            wScroll: 0,
            fullScreenMenu: false,
            pageLoaded: false,
        };

        this.listenToScroll = this.listenToScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.listenToScroll);

        this.setState({ pageLoaded: true });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                fullScreenMenu: false,
            });
        }
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;

        const scrolled = winScroll / height;

        this.setState({
            wScroll: scrolled,
        });
    };

    render() {
        return (
            <>
                {this.state.fullScreenMenu === true && (
                    <div className="fullScreenMenuBlock shadow">
                        <ul className="block menu">
                            <li className="item">
                                <NavLink to="/">
                                    {this.props.lang === "bg"
                                        ? i18bg.home
                                        : i18en.home}
                                </NavLink>
                            </li>
                            <li className="item">
                                <NavLink to="/buy-property">
                                    {this.props.lang === "bg"
                                        ? i18bg.buy
                                        : i18en.buy}
                                </NavLink>
                            </li>
                            <li className="item">
                                <NavLink to="/sell-property">
                                    {this.props.lang === "bg"
                                        ? i18bg.sell
                                        : i18en.sell}
                                </NavLink>
                            </li>
                            <li className="item">
                                <NavLink to="/rent-property">
                                    {this.props.lang === "bg"
                                        ? i18bg.rent
                                        : i18en.rent}
                                </NavLink>
                            </li>
                            <li className="item">
                                <NavLink to="/search">
                                    {this.props.lang === "bg"
                                        ? i18bg.search
                                        : i18en.search}
                                </NavLink>
                            </li>
                            <li className="item">
                                <NavLink to="/services">
                                    {this.props.lang === "bg"
                                        ? i18bg.services
                                        : i18en.services}
                                </NavLink>
                            </li>
                            <li className="item">
                                <NavLink to="/contacts">
                                    {this.props.lang === "bg"
                                        ? i18bg.contacts
                                        : i18en.contacts}
                                </NavLink>
                            </li>
                            <li className="item">
                                <NavLink to="/about-us">
                                    {this.props.lang === "bg"
                                        ? i18bg.about
                                        : i18en.about}
                                </NavLink>
                            </li>
                            <li className="item">
                                <button
                                    onClick={() => {
                                        this.setState({
                                            fullScreenMenu: false,
                                        });
                                    }}
                                >
                                    <img src={closeMenuIcon} alt="Close" />
                                </button>
                            </li>
                        </ul>
                    </div>
                )}
                <div
                    className={
                        this.state.wScroll > 0.1
                            ? "block s-fs darkShadow opacity"
                            : "block s-fs"
                    }
                    style={{
                        position: "fixed",
                        zIndex: 9999,
                        backgroundColor: "#fff",
                    }}
                >
                    <div className="block s-md">
                        <nav>
                            {this.state.fullScreenMenu === false && (
                                <ul
                                    className="block fullWidthMenu"
                                    style={{
                                        backgroundImage:
                                            this.props.lang === "bg"
                                                ? `url(${LOGO_BG})`
                                                : `url(${LOGO_EN})`,
                                    }}
                                >
                                    <li className="item">
                                        <button
                                            onClick={() => {
                                                this.setState(
                                                    {
                                                        fullScreenMenu: !this
                                                            .state
                                                            .fullScreenMenu,
                                                    },
                                                    () => {
                                                        if (
                                                            this.state
                                                                .fullScreenMenu ===
                                                            true
                                                        ) {
                                                            window.scrollTo(
                                                                0,
                                                                0
                                                            );
                                                        }
                                                    }
                                                );
                                            }}
                                        >
                                            <img
                                                src={menuIcon}
                                                alt="Fullscreen menu"
                                            />
                                        </button>
                                    </li>
                                </ul>
                            )}

                            <ul className="block menu">
                                <li className="item">
                                    <NavLink to="/">
                                        {this.props.lang === "bg"
                                            ? i18bg.home
                                            : i18en.home}
                                    </NavLink>
                                </li>
                                <li className="item">
                                    <NavLink to="/buy-property">
                                        {this.props.lang === "bg"
                                            ? i18bg.buy
                                            : i18en.buy}
                                    </NavLink>
                                </li>
                                <li className="item">
                                    <NavLink to="/sell-property">
                                        {this.props.lang === "bg"
                                            ? i18bg.sell
                                            : i18en.sell}
                                    </NavLink>
                                </li>
                                <li className="item">
                                    <NavLink to="/rent-property">
                                        {this.props.lang === "bg"
                                            ? i18bg.rent
                                            : i18en.rent}
                                    </NavLink>
                                </li>
                                <li
                                    className={
                                        this.state.pageLoaded === true
                                            ? this.state.wScroll > 0.1
                                                ? "item_logo mediumSizeMenuBg"
                                                : "item_logo fullSizeMenuBg"
                                            : this.state.wScroll > 0.1
                                                ? "item_logo"
                                                : "item_logo mediumSizeMenuBg"
                                    }
                                    style={{
                                        backgroundImage:
                                            this.props.lang === "bg"
                                                ? `url(${LOGO_BG})`
                                                : `url(${LOGO_EN})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center center",
                                    }}
                                ></li>
                                <li className="item">
                                    <NavLink to="/search">
                                        {this.props.lang === "bg"
                                            ? i18bg.search
                                            : i18en.search}
                                    </NavLink>
                                </li>
                                <li className="item">
                                    <NavLink to="/services">
                                        {this.props.lang === "bg"
                                            ? i18bg.services
                                            : i18en.services}
                                    </NavLink>
                                </li>
                                <li className="item">
                                    <NavLink to="/contacts">
                                        {this.props.lang === "bg"
                                            ? i18bg.contacts
                                            : i18en.contacts}
                                    </NavLink>
                                </li>
                                <li className="item">
                                    <NavLink to="/about-us">
                                        {this.props.lang === "bg"
                                            ? i18bg.about
                                            : i18en.about}
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang,
    };
};

export default withRouter(connect(mapStateToProps)(Header));
