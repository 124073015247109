import React, { Component } from "react";
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { i18bg, i18en } from "./../locale/landingfaq"

import firstImage from "./../images/faq/1.png";
import secondImage from "./../images/faq/2.png";
import thirdImage from "./../images/faq/3.png";
import fourthImage from "./../images/faq/propertymanagement.webp"

class LandingFAQ extends Component {
  render() {
    return (
      <div className="block s-fs">
        <div className="block s-md" style={{ marginTop: 78 }}>
          <div className="landingFAQ">
            <div
              className="item shadow"
              style={{ backgroundImage: `url(${firstImage})` }}
            >
              <div className="title">
                <Link to="/buy-property">
                  {this.props.lang === "bg"? i18bg.buytitle : i18en.buytitle}
                </Link>
              </div>
              <div className="description">
                {this.props.lang === "bg"? i18bg.buydescription : i18en.buydescription}
              </div>
            </div>
            <div
              className="item shadow"
              style={{ backgroundImage: `url(${secondImage})` }}
            >
              <div className="title">
                <Link to="/sell-property">
                  {this.props.lang === "bg"? i18bg.selltitle : i18en.selltitle}
                </Link>
              </div>
              <div className="description">
                {this.props.lang === "bg"? i18bg.selldescription : i18en.selldescription}
              </div>
            </div>
            <div
              className="item shadow"
              style={{ backgroundImage: `url(${thirdImage})` }}
            >
              <div className="title">
                <Link to="/rent-property">
                  {this.props.lang === "bg"? i18bg.renttitle : i18en.renttitle}
                </Link>
              </div>
              <div className="description">
                {this.props.lang === "bg"? i18bg.rentdescription : i18en.rentdescription}
              </div>
            </div>
          </div>
        </div>
        <div className="block s-md" style={{ marginTop: 15 }}>
          <div className="landingFAQPropertyManagement shadow" style={{ backgroundImage: `url(${fourthImage})`}}>
            <Link to="/property-management">{this.props.lang === "bg"? i18bg.propertymanagement : i18en.propertymanagement}</Link>
          </div>
          <center>
            <hr style={{ width: 140, marginTop: 72 }} />
          </center>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      lang: state.lang.lang
  };
};

export default connect(mapStateToProps)(LandingFAQ)
