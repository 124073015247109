import {
    LOGIN,
    LOGOUT
} from './../actions/auth'

let checkUser = localStorage.getItem('user')
let userCredentials = {}
if (checkUser !== null) {
    userCredentials = JSON.parse(localStorage.getItem('user'))
}

let initialState = {
    user: userCredentials
}

export function auth(state = initialState, action) {

    switch (action.type) {

        case LOGIN:

            return Object.assign({}, state, {
                user: action.user
            })

        case LOGOUT:

            return Object.assign({}, state, {
                user: {}
            })

        default:

            return initialState
    }
}