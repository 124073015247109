export const i18bg = {
    title: "Ексклузивни имоти",
    subtitle: "Бъди сред първите разглеждащи обяви, предлагани само от нас.",
    allexclusive: "Всички ексклузивни обяви"
}

export const i18en = {
    title: "Exclusive properties",
    subtitle: "Be among the first viewing offers offered only by us.",
    allexclusive: "All exclusive offers"
}