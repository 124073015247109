export const SET_BG = 'set_bg'
export const SET_EN = 'set_en'

export const actionSetBG = () => {

    return dispatch => {

        dispatch({ 
            type: SET_BG,
            lang: "bg"
        })

        localStorage.removeItem('lang')
        localStorage.setItem('lang', "bg")
    }
}

export const actionSetEN = () => {

    return dispatch => {

        dispatch({ 
            type: SET_EN,
            lang: "en"
        })

        localStorage.removeItem('lang')
        localStorage.setItem('lang', "en")
    }
}