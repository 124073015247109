import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import LandingSearch from "./../Components/LandingSearch";
import LandingExclusive from "./../Components/LandingExclusive";
import LandingLastProperties from "./../Components/LandingLastProperties"
import LandingFAQ from "./../Components/LandingFAQ";
import LandingBlog from "./../Components/LandingBlog";
import LandingTeam from "./../Components/LandingTeam";

class LandingPage extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {this.props.lang === "bg"
                            ? process.env.REACT_APP_title_LandingPage_bg
                            : process.env.REACT_APP_title_LandingPage_en}
                        {" | "}
                        {this.props.lang === "bg"
                            ? process.env.REACT_APP_title_CompanyName_bg
                            : process.env.REACT_APP_title_CompanyName_en}
                    </title>
                </Helmet>
                <LandingSearch />
                <LandingExclusive />
                <LandingLastProperties />
                <LandingFAQ />
                <LandingBlog />
                <LandingTeam />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang,
    };
};

export default connect(mapStateToProps)(LandingPage);
