import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";

import { globalhosturl, globalhostapi } from "./../config/variables";
import { i18bg, i18en } from "./../locale/landingrecent";

import mailIcon from "./../images/mail.png";
import surfaceIcon from "./../images/surface.png";
import floorIcon from "./../images/floor.png";

class LandingLastProperties extends Component {
    constructor(props) {
        super(props);

        this.state = {
            properties: [],
            Img0: "",
            Img1: "",
            Img2: "",
        };
    }

    componentDidMount() {
        axios.get(globalhostapi + "property/recent/3").then((response) => {
            this.setState(
                {
                    properties: response.data,
                },
                () => {
                    if (
                        response.data[0] &&
                        response.data[0].pictures !== null
                    ) {
                        let str = response.data[0].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img0:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img0:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[1] &&
                        response.data[1].pictures !== null
                    ) {
                        let str = response.data[1].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img1:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img1:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[2] &&
                        response.data[2].pictures !== null
                    ) {
                        let str = response.data[2].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img2:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img2:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                }
            );
        });
    }

    render() {
        if (this.state.properties.length < 3) {
            return <></>;
        }

        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <h1 className="landingLastPropertiesTitle">
                        {this.props.lang === "bg" ? i18bg.title : i18en.title}
                    </h1>

                    <div className="landingLastProperties">
                        <Link to={"/property/" + this.state.properties[0].id}>
                            <div className="property shadow">
                                <div
                                    className="propertyImage"
                                    style={{
                                        backgroundImage: `url(${this.state.Img0})`,
                                    }}
                                >
                                    <div className="propertyPrice">
                                        € {this.state.properties[0].price}
                                    </div>
                                </div>
                                <div className="propertyInformation">
                                    <p className="propertyLocation">
                                        {this.props.lang === "bg"
                                            ? this.state.properties[0]
                                                  .placeBgName
                                            : this.state.properties[0]
                                                  .placeEnName}
                                    </p>
                                    <p className="propertyAgent">
                                        <a href={"mailto:" + this.state.properties[0].brokerEmail}>
                                            <img src={mailIcon} alt="Mail" />
                                        </a>
                                    </p>
                                    <p className="propertyArea">
                                        {this.props.lang === "bg"
                                            ? this.state.properties[0]
                                                  .regionBgName
                                            : this.state.properties[0]
                                                  .regionEnName}
                                    </p>
                                    <p className="propertySquare">
                                        <img
                                            src={surfaceIcon}
                                            alt="Square meters"
                                        />{" "}
                                        {this.state.properties[0].square}{" "}
                                        {this.props.lang === "bg"
                                            ? i18bg.sqm
                                            : i18en.sqm}
                                    </p>
                                    <p className="propertyFloor">
                                        <img src={floorIcon} alt="Floor" />{" "}
                                        {this.state.properties[0].floor}{" "}
                                        {this.props.lang === "bg"
                                            ? i18bg.floor
                                            : i18en.floor}
                                    </p>
                                </div>
                            </div>
                        </Link>

                        <Link to={"/property/" + this.state.properties[1].id}>
                            <div className="property shadow">
                                <div
                                    className="propertyImage"
                                    style={{
                                        backgroundImage: `url(${this.state.Img1})`,
                                    }}
                                >
                                    <div className="propertyPrice">
                                        € {this.state.properties[1].price}
                                    </div>
                                </div>
                                <div className="propertyInformation">
                                    <p className="propertyLocation">
                                        {this.props.lang === "bg"
                                            ? this.state.properties[1]
                                                  .placeBgName
                                            : this.state.properties[1]
                                                  .placeEnName}
                                    </p>
                                    <p className="propertyAgent">
                                        <a href={"mailto:" + this.state.properties[1].brokerEmail}>
                                            <img src={mailIcon} alt="Mail" />
                                        </a>
                                    </p>
                                    <p className="propertyArea">
                                        {this.props.lang === "bg"
                                            ? this.state.properties[1]
                                                  .regionBgName
                                            : this.state.properties[1]
                                                  .regionEnName}
                                    </p>
                                    <p className="propertySquare">
                                        <img
                                            src={surfaceIcon}
                                            alt="Square meters"
                                        />{" "}
                                        {this.state.properties[1].square}{" "}
                                        {this.props.lang === "bg"
                                            ? " кв.м."
                                            : " sq.m."}
                                    </p>
                                    <p className="propertyFloor">
                                        <img src={floorIcon} alt="Floor" />{" "}
                                        {this.state.properties[1].floor}{" "}
                                        {this.props.lang === "bg"
                                            ? " етаж"
                                            : " floor"}
                                    </p>
                                </div>
                            </div>
                        </Link>

                        <Link to={"/property/" + this.state.properties[2].id}>
                            <div className="property shadow">
                                <div
                                    className="propertyImage"
                                    style={{
                                        backgroundImage: `url(${this.state.Img2})`,
                                    }}
                                >
                                    <div className="propertyPrice">
                                        € {this.state.properties[2].price}
                                    </div>
                                </div>
                                <div className="propertyInformation">
                                    <p className="propertyLocation">
                                        {this.props.lang === "bg"
                                            ? this.state.properties[2]
                                                  .placeBgName
                                            : this.state.properties[2]
                                                  .placeEnName}
                                    </p>
                                    <p className="propertyAgent">
                                        <a href={"mailto:" + this.state.properties[2].brokerEmail}>
                                            <img src={mailIcon} alt="Mail" />
                                        </a>
                                    </p>
                                    <p className="propertyArea">
                                        {this.props.lang === "bg"
                                            ? this.state.properties[2]
                                                  .regionBgName
                                            : this.state.properties[2]
                                                  .regionEnName}
                                    </p>
                                    <p className="propertySquare">
                                        <img
                                            src={surfaceIcon}
                                            alt="Square meters"
                                        />{" "}
                                        {this.state.properties[2].square}{" "}
                                        {this.props.lang === "bg"
                                            ? " кв.м."
                                            : " sq.m."}
                                    </p>
                                    <p className="propertyFloor">
                                        <img src={floorIcon} alt="Floor" />{" "}
                                        {this.state.properties[2].floor}{" "}
                                        {this.props.lang === "bg"
                                            ? " етаж"
                                            : " floor"}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <center>
                        <hr style={{ width: 140, marginTop: 78 }} />
                    </center>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang,
    };
};

export default connect(mapStateToProps)(LandingLastProperties);
