import React, { Component } from "react";
import axios from "axios";
import { globalhosturl, globalhostapi } from "../config/variables"
import toastr from "toastr";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ManagementNav from "../Components/ManagementNav";

class ManagementBlogEditPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            title: "",
            description: "",
            sort: 10,
            visible: "checked",
            picture: "",
            newPicture: "",
        };

        this.editPost = this.editPost.bind(this);
        this.deletePicture = this.deletePicture.bind(this);
    }

    componentDidMount() {
        axios
            .get(
                globalhostapi + "blog/bg/post/one/" +
                    this.props.match.params.id
            )
            .then((response) => {
                this.setState({
                    id: response.data.id,
                    title: response.data.title,
                    description: response.data.description,
                    sort: response.data.sort,
                    visible: response.data.visible === "Y" ? "checked" : "",
                    picture: response.data.picture,
                    newPicture: response.data.picture,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    editPost(e) {
        const formValues = new FormData();

        formValues.append("id", this.state.id);
        formValues.append("title", this.state.title);
        formValues.append("description", this.state.description);
        formValues.append("sort", this.state.sort);
        formValues.append(
            "visible",
            this.state.visible === "checked" ? "Y" : "N"
        );

        if (this.state.newPicture !== this.state.picture) {
            formValues.append("picture", this.state.newPicture);
        }

        axios
            .post(
                globalhostapi + "blog/bg/post/edit/" +
                    this.props.match.params.id,
                formValues
            )
            .then(() => {
                axios
                .get(
                    globalhostapi + "blog/bg/post/one/" +
                        this.props.match.params.id
                )
                .then((response) => {
                    this.setState({
                        id: response.data.id,
                        title: response.data.title,
                        description: response.data.description,
                        sort: response.data.sort,
                        visible: response.data.visible === "Y" ? "checked" : "",
                        picture: response.data.picture,
                        newPicture: response.data.picture,
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
                toastr.success("Успешно редактирана новина.");
            })
            .catch((error) => {
                toastr.warning("Неуспешно редактирането на новина.");
            });
    }

    deletePicture(e) {
        e.preventDefault();

        if (window.confirm("Изтрий снимката?")) {
            axios
                .delete(
                    globalhostapi + "blog/bg/post/delete/" +
                        this.state.id +
                        "/picture"
                )
                .then(() => {
                    axios
                    .get(
                        globalhostapi + "blog/bg/post/one/" +
                            this.props.match.params.id
                    )
                    .then((response) => {
                        this.setState({
                            id: response.data.id,
                            title: response.data.title,
                            description: response.data.description,
                            sort: response.data.sort,
                            visible: response.data.visible === "Y" ? "checked" : "",
                            picture: response.data.picture,
                            newPicture: response.data.picture,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                    toastr.success("Успешно изтрита снимка на новина.");
                })
                .catch((err) => {
                    toastr.warning("Неуспешно изтриването на снимка.");
                });
        }
    }

    selectImage = (event) => {
        this.setState({ newPicture: event.target.files[0] });
    };

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="addBlog shadow">
                            <h3>Редактирай новина</h3>
                            <br />
                            <label htmlFor="title">Заглавие</label>
                            <input
                                type="text"
                                name="title"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        title: e.target.value,
                                    });
                                }}
                                value={this.state.title}
                                required="required"
                            />

                            <label htmlFor="description">Текст</label>
                            <CKEditor
                                data={this.state.description}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.setState({
                                        description: data,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="sort">Приоритет</label>
                            <input
                                type="text"
                                name="sort"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        sort: e.target.value,
                                    });
                                }}
                                value={this.state.sort}
                                required="required"
                            />

                            <label htmlFor="visible">Показване</label>
                            <input
                                type="checkbox"
                                name="visible"
                                checked={this.state.visible}
                                onChange={() => {
                                    if (this.state.visible === "checked") {
                                        this.setState({
                                            visible: "",
                                        });
                                    } else {
                                        this.setState({
                                            visible: "checked",
                                        });
                                    }
                                }}
                            />

                            {this.state.picture.length > 0 && (
                                <div>
                                    <label htmlFor="profilePicture">
                                        Снимка
                                    </label>
                                    <img
                                        className="profilePicture"
                                        src={
                                            globalhosturl +
                                            this.state.picture.replace(
                                                "public",
                                                "storage"
                                            )
                                        }
                                        alt="Post"
                                    />
                                    <button
                                        className="deleteProfilePicture"
                                        onClick={this.deletePicture}
                                    >
                                        Изтрий снимката
                                    </button>
                                </div>
                            )}

                            <label htmlFor="file">Добави снимката</label>
                            <input type="file" onChange={this.selectImage} />

                            <input
                                type="button"
                                onClick={this.editPost}
                                value="Редактирай"
                            />
                        </div>
                        <div className="listProperties shadow"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementBlogEditPage;
