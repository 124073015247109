import { globalhostapi } from "./../config/variables"

export const START_LOADING = 'start_loading'
export const STOP_LOADING = 'stop_loading'

export const LOGIN = 'login'
export const LOGOUT = 'logout'

export const actionLogin = (credentials) => {

    return dispatch => {

        login(credentials)
            .then(response => response.json())
            .then(data => {
                if (Object.keys(data).length !== 0) {

                    dispatch({ 
                        type: LOGIN,
                        user: data
                    })

                    let userCredentials = JSON.stringify(data)

                    localStorage.setItem('user', userCredentials)
                }
            })
            .catch(error => console.log(error))
    }
}

async function login(credentials) {
    return await fetch(globalhostapi + 'auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
}

export const actionLogout = () => {

    return async dispatch => {

        let credentials = JSON.parse(localStorage.getItem("user"))

        await logout(credentials)
            .then(response => response.json())
            .then(data => {
                if (data === true) {

                    dispatch({ 
                        type: LOGOUT,
                        user: {}
                    })

                    localStorage.removeItem('user')
                }
            })
            .catch(error => console.log(error))
    }
}

async function logout(credentials) {
    return await fetch(globalhostapi + 'auth/logout', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
}