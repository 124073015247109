import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import formatCurrency from "./../helpers/formatCurrency"

class ExclusiveProperty extends Component {
    render() {
        if (this.props.data === undefined) {
            return (<div></div>)
        }

        let placeBgName = this.props.data.placeBgName.replace(/област/, '').replace(/град/, '')
        let placeEnName = this.props.data.placeEnName.replace(/district/, '')

        let bgTypeName = this.props.data.bg_name
        let enTypeName = this.props.data.en_name

        return (<Link to={"/property/"+ this.props.data.id}>
            <div>
            <div className="price darkShadow">
                {this.props.data.currency === "EURO" && "€ "}
                {formatCurrency(this.props.data.price)}
            </div>
            <div className="title">
                {this.props.lang === "bg"? placeBgName : placeEnName}
            </div>
            <div className="link">
                {this.props.lang === "bg"? bgTypeName : enTypeName}
            </div>
        </div>
        </Link>)
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang
    };
};

export default connect(mapStateToProps)(ExclusiveProperty)