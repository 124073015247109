import React, { Component } from "react";
import axios from "axios";
import { globalhostapi } from "./../config/variables"
import toastr from "toastr";
import ManagementNav from "../Components/ManagementNav";

class ManagementTypesEditPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allTypes: [],
            id: 0,
            bg_name: "",
            en_name: "",
            type_id: 0,
            sort: 0,
            visible: "",
        };

        this.editSubtype = this.editSubtype.bind(this);
    }

    componentDidMount() {
        axios
            .get(
                globalhostapi + "subtype/one/" +
                    this.props.match.params.id
            )
            .then((response) => {
                this.setState({
                    id: response.data.id,
                    bg_name: response.data.bg_name,
                    en_name: response.data.en_name,
                    type_id: response.data.t_id,
                    sort: response.data.sort,
                    visible: response.data.visible === "Y" ? "checked" : "",
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        axios
            .get(globalhostapi + "type/all")
            .then((response) => {
                this.setState({
                    allTypes: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    editSubtype() {
        axios
            .post(
                globalhostapi + "subtype/edit/" +
                    this.props.match.params.id,
                {
                    id: this.state.id,
                    bg_name: this.state.bg_name,
                    en_name: this.state.en_name,
                    t_id: this.state.type_id,
                    sort: this.state.sort,
                    visible: this.state.visible === "checked" ? "Y" : "N",
                }
            )
            .then(() => {
                axios
                .get(
                    globalhostapi + "subtype/one/" +
                        this.props.match.params.id
                )
                .then((response) => {
                    this.setState({
                        id: response.data.id,
                        bg_name: response.data.bg_name,
                        en_name: response.data.en_name,
                        type_id: response.data.t_id,
                        sort: response.data.sort,
                        visible: response.data.visible === "Y" ? "checked" : "",
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
                toastr.success("Успешно редактиран подтип имот.");
            })
            .catch((error) => {
                toastr.warning("Неуспешно редактиран подтип имот.");
            });
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="addType shadow">
                            <h3>Редактирай подтип имот</h3>
                            <br />
                            <label htmlFor="bg_name">Име на български</label>
                            <input
                                type="text"
                                name="bg_name"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        bg_name: e.target.value,
                                    });
                                }}
                                value={this.state.bg_name}
                                required="required"
                            />
                            <label htmlFor="en_name">Име на английски</label>
                            <input
                                type="text"
                                name="en_name"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        en_name: e.target.value,
                                    });
                                }}
                                value={this.state.en_name}
                                required="required"
                            />

                            <label htmlFor="type_id">Тип на имота</label>
                            <select
                                name="type_id"
                                onChange={(e) => {
                                    this.setState({
                                        type_id: e.target.value,
                                    });
                                }}
                                required="required"
                            >
                                <option value="">Избери</option>
                                {this.state.allTypes.length > 0
                                    ? this.state.allTypes.map((type, id) => {
                                          return (
                                              <option
                                                  value={type.id}
                                                  key={id}
                                                  selected={
                                                      type.id ===
                                                      this.state.type_id
                                                          ? "selected"
                                                          : ""
                                                  }
                                              >
                                                  {type.bg_name}
                                              </option>
                                          );
                                      })
                                    : ""}
                            </select>

                            <label htmlFor="sort">Приоритет</label>
                            <input
                                type="text"
                                name="sort"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        sort: e.target.value,
                                    });
                                }}
                                value={this.state.sort}
                                required="required"
                            />
                            <label htmlFor="visible">Показване</label>
                            <input
                                type="checkbox"
                                name="visible"
                                checked={this.state.visible}
                                onChange={() => {
                                    if (this.state.visible === "checked") {
                                        this.setState({
                                            visible: "",
                                        });
                                    } else {
                                        this.setState({
                                            visible: "checked",
                                        });
                                    }
                                }}
                            />
                            <input
                                type="button"
                                onClick={this.editSubtype}
                                value="Редактирай"
                            />
                        </div>
                        <div className="listProperties shadow"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementTypesEditPage;
