import React, { Component } from "react";
import { connect } from "react-redux";
import { actionLogin } from "./../actions/auth";

class ManagementPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
        };

        this.doLogin = this.doLogin.bind(this);
    }

    componentDidUpdate() {
        if (Object.keys(this.props.auth.user).length > 0) {
            window.location.reload();
        }
    }

    doLogin() {
        this.props.login({
            usr_name: this.state.username,
            usr_pass: this.state.password,
        });
    }

    render() {
        return (
            <>
                <h1 className="managementTitle">Логин</h1>
                <div className="loginForm shadow">
                    <input
                        type="text"
                        style={{ marginRight: 35 }}
                        className="shadow"
                        placeholder="Потребител"
                        onChange={(e) => {
                            this.setState({
                                username: e.target.value,
                            });
                        }}
                    />
                    <input
                        type="password"
                        className="shadow"
                        placeholder="Парола"
                        onChange={(e) => {
                            this.setState({
                                password: e.target.value,
                            });
                        }}
                    />
                    <button onClick={this.doLogin}>Вход</button>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => ({
    login: (credentials) => dispatch(actionLogin(credentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagementPage);
