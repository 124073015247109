export const i18bg = {
    buytitle: "Купи",
    buydescription: "Направете покупката на имот приятно, сигурно и успешно начинание, като ползвате нашите професионални услуги и дългогодишен опит.",
    selltitle: "Продай",
    selldescription: "Ако вече имате решение за продажба на Вашия имот, е необходимо да получите максимално вярна първоначална информация за състоянието на пазара на имоти. С наша помощ бързо, лесно и удобно чрез платформата ни!",
    renttitle: "Наеми",
    rentdescription: "Отдавате жилище или търсите апартамент под наем? Агенция Болкан Естейт Ви гарантира бърза и успешна сделка! Доверете се на нашите консултанти и техният професионализъм!",
    propertymanagement: "Управление на недвижими имоти"
}

export const i18en = {
    buytitle: "Buy",
    buydescription: "Make the purchase of a property a pleasant, safe and successful endeavor, using our professional services and experience.",
    selltitle: "Sell",
    selldescription: "If you already have a decision to sell your property, you need to get the most accurate initial information about the state of the real estate market. With our help quickly, easily and conveniently through our platform!",
    renttitle: "Rent",
    rentdescription: "Are you renting an apartment or looking for an apartment for rent? Balkan Estate Agency guarantees you a fast and successful deal! Trust our consultants and their professionalism!",
    propertymanagement: "Real estate management"
}