import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom"
import axios from "axios";
import { globalhosturl, globalhostapi } from "./../config/variables";
import { i18bg, i18en } from "./../locale/landingexclusive";

import ExclusiveProperty from "./ExclusiveProperty";
import ExclusiveMiniProperty from "./ExclusiveMiniProperty";

import ArrowDown from "./../images/arrow-down.png";

class LandingExclusive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exclusiveProperties: [],
            exclusivePage: 0,
            Img0: "",
            Img1: "",
            Img2: "",
            Img3: "",
            Img4: "",
            Img5: "",
            Img6: "",
            Img7: "",
            Img8: "",
            Img9: "",
            Img10: "",
            Img11: "",
        };

        this.loadLess = this.loadLess.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount() {
        axios.get(globalhostapi + "property/exclusive/12").then((response) => {
            this.setState(
                {
                    exclusiveProperties: response.data,
                },
                () => {
                    if (
                        response.data[0] &&
                        response.data[0].pictures !== null
                    ) {
                        let str = response.data[0].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img0:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img0:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[1] &&
                        response.data[1].pictures !== null
                    ) {
                        let str = response.data[1].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img1:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img1:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[2] &&
                        response.data[2].pictures !== null
                    ) {
                        let str = response.data[2].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img2:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img2:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[3] &&
                        response.data[3].pictures !== null
                    ) {
                        let str = response.data[3].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img3:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img3:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[4] &&
                        response.data[4].pictures !== null
                    ) {
                        let str = response.data[4].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img4:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img4:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[5] &&
                        response.data[5].pictures !== null
                    ) {
                        let str = response.data[5].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img5:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img5:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[6] &&
                        response.data[6].pictures !== null
                    ) {
                        let str = response.data[6].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img6:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img6:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[7] &&
                        response.data[7].pictures !== null
                    ) {
                        let str = response.data[7].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img7:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img7:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[8] &&
                        response.data[8].pictures !== null
                    ) {
                        let str = response.data[8].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img8:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img8:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[9] &&
                        response.data[9].pictures !== null
                    ) {
                        let str = response.data[9].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img9:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img9:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[10] &&
                        response.data[10].pictures !== null
                    ) {
                        let str = response.data[10].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img10:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img10:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                    if (
                        response.data[11] &&
                        response.data[11].pictures !== null
                    ) {
                        let str = response.data[11].pictures
                            .split(",")[0]
                            .replace(/[^A-Za-z0-9./-]/g, "");
                        if (str.startsWith("p")) {
                            this.setState({
                                Img11:
                                    globalhosturl +
                                    str.replace("public", "storage"),
                            });
                        } else {
                            this.setState({
                                Img11:
                                    globalhosturl +
                                    str.replace(
                                        "/data/image",
                                        "storage/images"
                                    ),
                            });
                        }
                    }
                }
            );
        });
    }

    loadLess() {
        this.setState({
            exclusivePage: 0,
        });
    }

    loadMore() {
        this.setState({
            exclusivePage: 6,
        });
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <h1 className="landingExclusivePropertiesTitle">
                        {this.props.lang === "bg" ? i18bg.title : i18en.title}
                    </h1>
                    <p className="landingExclusivePropertiesSubtitle">
                        {this.props.lang === "bg"
                            ? i18bg.subtitle
                            : i18en.subtitle}
                    </p>
                    <center>
                        <img src={ArrowDown} alt="Arrow" />
                    </center>
                    <div className="landingExclusiveProperties">
                        <div className="landingExclusivePropertiesItems">
                            <div
                                className="item shadow"
                                style={{
                                    backgroundImage: `url(${
                                        this.state.exclusivePage === 0
                                            ? this.state.Img0
                                            : this.state.Img6
                                    })`,
                                }}
                            >
                                {this.state.exclusiveProperties[0] && (
                                    <ExclusiveProperty
                                        data={
                                            this.state.exclusiveProperties[
                                                0 + this.state.exclusivePage
                                            ]
                                        }
                                    />
                                )}
                            </div>
                            <div className="item_big">
                                <div
                                    className="item shadow"
                                    style={{
                                        backgroundImage: `url(${
                                            this.state.exclusivePage === 0
                                                ? this.state.Img1
                                                : this.state.Img7
                                        })`,
                                    }}
                                >
                                    {this.state.exclusiveProperties[1] && (
                                        <ExclusiveMiniProperty
                                            data={
                                                this.state.exclusiveProperties[
                                                    1 + this.state.exclusivePage
                                                ]
                                            }
                                        />
                                    )}
                                </div>
                                <div
                                    className="item shadow"
                                    style={{
                                        backgroundImage: `url(${
                                            this.state.exclusivePage === 0
                                                ? this.state.Img2
                                                : this.state.Img8
                                        })`,
                                    }}
                                >
                                    {this.state.exclusiveProperties[2] && (
                                        <ExclusiveMiniProperty
                                            data={
                                                this.state.exclusiveProperties[
                                                    2 + this.state.exclusivePage
                                                ]
                                            }
                                        />
                                    )}
                                </div>
                                <div
                                    className="item shadow"
                                    style={{
                                        backgroundImage: `url(${
                                            this.state.exclusivePage === 0
                                                ? this.state.Img3
                                                : this.state.Img9
                                        })`,
                                    }}
                                >
                                    {this.state.exclusiveProperties[3] && (
                                        <ExclusiveMiniProperty
                                            data={
                                                this.state.exclusiveProperties[
                                                    3 + this.state.exclusivePage
                                                ]
                                            }
                                        />
                                    )}
                                </div>
                                <div
                                    className="item shadow"
                                    style={{
                                        backgroundImage: `url(${
                                            this.state.exclusivePage === 0
                                                ? this.state.Img4
                                                : this.state.Img10
                                        })`,
                                    }}
                                >
                                    {this.state.exclusiveProperties[4] && (
                                        <ExclusiveMiniProperty
                                            data={
                                                this.state.exclusiveProperties[
                                                    4 + this.state.exclusivePage
                                                ]
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div
                                className="item shadow"
                                style={{
                                    backgroundImage: `url(${
                                        this.state.exclusivePage === 0
                                            ? this.state.Img5
                                            : this.state.Img11
                                    })`,
                                }}
                            >
                                {this.state.exclusiveProperties[5] && (
                                    <ExclusiveProperty
                                        data={
                                            this.state.exclusiveProperties[
                                                5 + this.state.exclusivePage
                                            ]
                                        }
                                    />
                                )}
                            </div>
                        </div>

                        {this.state.exclusivePage === 6 && <div className="load_less shadow" onClick={this.loadLess}></div>}
                        {this.state.exclusiveProperties.length > 6 && this.state.exclusivePage === 0? <div className="load_more shadow" onClick={this.loadMore}></div> : ""}

                    </div>
                    <div
                        className="block s-md"
                        style={{ paddingTop: 15, paddingBottom: 9 }}
                    >
                        <div className="allExclusive">
                            <Link to="/search?offer=0&type=0&region=0&place=0&price=0&exclusive=checked&limit_skip=0&order_by=desc">
                                {this.props.lang === "bg"? i18bg.allexclusive : i18en.allexclusive}
                            </Link>
                        </div>
                    </div>
                    <center>
                        <hr style={{ width: 140, marginTop: 78 }} />
                    </center>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang,
    };
};

export default connect(mapStateToProps)(LandingExclusive);
