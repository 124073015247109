import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { actionLogout } from './../actions/auth'

class ManagementNav extends Component {
    constructor(props) {
        super(props)

        this.doLogout = this.doLogout.bind(this)
    }

    componentDidUpdate() {
        if (Object.keys(this.props.auth.user) < 1) {
            window.location.reload()
        }
    }

    doLogout() {
        this.props.logout()
        this.props.history.push('/management')
    }

    render() {
        return (
            <div>
                <h1 className="managementTitle">Администрация</h1>
                <div className="managementMenuContainer shadow">
                    <button className="">
                        <NavLink
                            to="/management/properties"
                            activeClassName="active"
                        >
                            Обяви
                        </NavLink>
                    </button>
                    <button className="">
                        <NavLink to="/management/types" activeClassName="active">
                            Имоти
                        </NavLink>
                    </button>
                    <button className="">
                        <NavLink to="/management/regions" activeClassName="active">
                            Места
                        </NavLink>
                    </button>
                    <button className="">
                        <NavLink to="/management/brokers" activeClassName="active">
                            Брокери
                        </NavLink>
                    </button>
                    <button className="">
                        <NavLink to="/management/blog" activeClassName="active">
                            Новини (блог)
                        </NavLink>
                    </button>
                    <button className="">
                        <NavLink to="/management/pages" activeClassName="active">
                            Страници
                        </NavLink>
                    </button>
                    <button className="">
                        <NavLink to="/management/users" activeClassName="active">
                            Потребители
                        </NavLink>
                    </button>
                    <button onClick={this.doLogout}>
                        Изход
                    </button>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(actionLogout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagementNav));
