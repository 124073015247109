import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { globalhosturl } from "./../config/variables"
import formatDate from '../helpers/formatDate.js'

class BlogPost extends Component {
    render() {
        if (this.props.data === undefined) {
            return (<div></div>)
        }
        return (
            <div
                className="item shadow"
                style={{
                    backgroundImage: `url(${globalhosturl + this.props.data.picture.replace(
                        "public",
                        "storage"
                    )})`,
                }}
            >
                <div className="description">
                    <div className="date">
                        {formatDate(
                            this.props.data.created_at,
                            this.props.lang
                        )}
                    </div>
                    <div className="title">
                        <Link to={"/post/"+ this.props.data.id}>{this.props.data.title}</Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang
    };
  };
  
  export default connect(mapStateToProps)(BlogPost)