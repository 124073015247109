import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { globalhostapi } from "./../config/variables"
import toastr from "toastr";
import ManagementNav from "./../Components/ManagementNav";

class ManagementUsersEditPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            names: "",
            email: "",
            usr_name: "",
            usr_pass: "",
            active: "",
        };

        this.editUser = this.editUser.bind(this);
    }

    componentDidMount() {
        axios
            .get(
                globalhostapi + "user/one/" +
                    this.props.match.params.id
            )
            .then((response) => {
                this.setState({
                    id: response.data.id,
                    names: response.data.names,
                    email: response.data.email,
                    usr_name: response.data.usr_name,
                    active: response.data.active === "Y" ? "checked" : "",
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    editUser() {
        axios
            .post(
                globalhostapi + "user/edit/" +
                    this.props.match.params.id,
                {
                    id: this.state.id,
                    names: this.state.names,
                    email: this.state.email,
                    usr_name: this.state.usr_name,
                    usr_pass: this.state.usr_pass,
                    active: this.state.active === "checked" ? "Y" : "N",
                    token: this.props.auth.user.token,
                    token_valid_until: this.props.auth.user.token_valid_until
                }
            )
            .then(() => {
                axios
                    .get(
                        globalhostapi + "user/one/" +
                            this.props.match.params.id
                    )
                    .then((response) => {
                        this.setState({
                            id: response.data.id,
                            names: response.data.names,
                            email: response.data.email,
                            usr_name: response.data.usr_name,
                            active:
                                response.data.active === "Y" ? "checked" : "",
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                toastr.success("Успешно редактиран потребител.");
            })
            .catch((error) => {
                toastr.warning("Неуспешно редактирането на потребител.");
            });
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="addUser shadow">
                            <h3>Редактирай потребител</h3>
                            <br />
                            <label htmlFor="names">Име на потребителя*</label>
                            <input
                                type="text"
                                name="names"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        names: e.target.value,
                                    });
                                }}
                                value={this.state.names}
                                required="required"
                            />
                            <label htmlFor="email">E-mail адрес*</label>
                            <input
                                type="text"
                                name="email"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        email: e.target.value,
                                    });
                                }}
                                value={this.state.email}
                                required="required"
                            />
                            <label htmlFor="usr_name">Потребителско име*</label>
                            <input
                                type="text"
                                name="usr_name"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        usr_name: e.target.value,
                                    });
                                }}
                                value={this.state.usr_name}
                                required="required"
                            />
                            <label htmlFor="usr_pass">
                                Потребителска парола*
                            </label>
                            <input
                                type="password"
                                name="usr_pass"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        usr_pass: e.target.value,
                                    });
                                }}
                                value={this.state.usr_pass}
                                required="required"
                            />
                            <label htmlFor="active">Статус</label>
                            <input
                                type="checkbox"
                                name="active"
                                checked={this.state.active}
                                onChange={() => {
                                    if (this.state.active === "checked") {
                                        this.setState({
                                            active: "",
                                        });
                                    } else {
                                        this.setState({
                                            active: "checked",
                                        });
                                    }
                                }}
                            />
                            <input
                                type="button"
                                onClick={this.editUser}
                                value="Редактирай"
                            />
                        </div>
                        <div className="listProperties shadow"></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(ManagementUsersEditPage);
