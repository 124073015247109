import React, { Component } from "react";
import axios from "axios";
import { globalhostapi } from "./../config/variables";
import toastr from "toastr";
import { Link } from "react-router-dom";

import ManagementNav from "./../Components/ManagementNav";

class ManagementBrokersPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allBrokers: [],

            add_code: "",
            add_bg_names: "",
            add_en_names: "",
            add_email: "",
            add_phone: "",
            add_mobile: "",
            add_image: "",
            add_at_homepage: "",
            add_homepage_priority: ""
        };

        this.addBroker = this.addBroker.bind(this);
        this.deleteBroker = this.deleteBroker.bind(this);
    }

    componentDidMount() {
        axios
            .get(globalhostapi + "broker/all")
            .then((response) => {
                this.setState({
                    allBrokers: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    addBroker(e) {
        e.preventDefault();

        const formValues = new FormData();
        formValues.append("code", this.state.add_code);
        formValues.append("bg_names", this.state.add_bg_names);
        formValues.append("en_names", this.state.add_en_names);
        formValues.append("email", this.state.add_email);
        formValues.append("phone", this.state.add_phone);
        formValues.append("mobile", this.state.add_mobile);
        formValues.append("image", this.state.add_image);
        formValues.append("at_homepage", this.state.add_at_homepage);
        formValues.append("homepage_priority", this.state.add_homepage_priority);

        axios
            .post(globalhostapi + "broker/add", formValues)
            .then(() => {
                axios
                    .get(globalhostapi + "broker/all")
                    .then((response) => {
                        this.setState({
                            allBrokers: response.data,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                toastr.success("Успешно добавен брокер.");
            })
            .catch(() => {
                toastr.warning("Неуспешно добавянето на брокер.");
            });
    }

    deleteBroker(id, bg_name, en_name) {
        if (window.confirm("Изтрий " + bg_name + "?")) {
            axios
                .delete(globalhostapi + "broker/delete/" + id)
                .then(() => {
                    axios
                        .get(globalhostapi + "broker/all")
                        .then((response) => {
                            this.setState({
                                allBrokers: response.data,
                            });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    toastr.success("Успешно изтрит брокер.");
                })
                .catch(() => {
                    toastr.warning("Неуспешно изтриването на брокер.");
                });
        }
    }

    selectImage = (event) => {
        this.setState({ add_image: event.target.files[0] });
    };

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <form
                            className="addBroker shadow"
                            onSubmit={this.addBroker}
                        >
                            <h3>Добави брокер</h3>
                            <br />
                            <label htmlFor="code">Код на брокера</label>
                            <input
                                type="text"
                                name="code"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        add_code: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="bg_names">Имена на български</label>
                            <input
                                type="text"
                                name="bg_names"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        add_bg_names: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="en_names">Имена на английски</label>
                            <input
                                type="text"
                                name="en_names"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        add_en_names: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="email">E-mail адрес</label>
                            <input
                                type="text"
                                name="email"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        add_email: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="phone">Телефон</label>
                            <input
                                type="text"
                                name="phone"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        add_phone: e.target.value,
                                    });
                                }}
                            />
                            <label htmlFor="mobile">Мобилен телефон</label>
                            <input
                                type="text"
                                name="mobile"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        add_mobile: e.target.value,
                                    });
                                }}
                            />
                            <label htmlFor="at_homepage">
                                Видим в начална страница
                            </label>
                            <select
                                onChange={(e) => {
                                    this.setState({
                                        add_at_homepage: e.target.value,
                                    });
                                }}
                                required="required"
                            >
                                <option value="0">Не</option>
                                <option value="1">Да</option>
                            </select>
                            <label htmlFor="homepage_priority">Подреждане на начална страница</label>
                            <input
                                type="text"
                                name="homepage_priority"
                                placeholder="1 е най-високото, 2, 3... към най-ниското"
                                onChange={(e) => {
                                    this.setState({
                                        add_homepage_priority: e.target.value,
                                    });
                                }}
                                required="required"
                            />
                            <label htmlFor="file">Добави снимка</label>
                            <input type="file" onChange={this.selectImage} />
                            <input type="submit" value="Добави" />
                        </form>
                        <div className="listProperties shadow">
                            <h3>Всички брокери</h3>
                            <br />
                            {this.state.allBrokers.length > 0
                                ? this.state.allBrokers.map((broker, id) => {
                                      return (
                                          <div className="property" key={id}>
                                              {broker.bg_names} ({broker.code})
                                              <button
                                                  onClick={() => {
                                                      this.deleteBroker(
                                                          broker.id,
                                                          broker.bg_names,
                                                          broker.en_names
                                                      );
                                                  }}
                                                  className="deleteButton"
                                              >
                                                  Премахни
                                              </button>
                                              <Link
                                                  to={
                                                      "/management/broker/edit/" +
                                                      broker.id
                                                  }
                                              >
                                                  <button className="editButton">
                                                      Редактирай
                                                  </button>
                                              </Link>
                                              {broker.at_homepage === 1 && (
                                                  <button className="visibleButton">
                                                      Публичен
                                                  </button>
                                              )}
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementBrokersPage;
