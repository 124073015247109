export const i18bg = {
    home: "Начало",
    buy: "Купи",
    sell: "Продай",
    rent: "Наеми",
    search: "Търсене",
    news: "Новини",
    services: "Услуги",
    contacts: "Контакти",
    about: "За нас"
}

export const i18en = {
    home: "Home",
    buy: "Buy",
    sell: "Sell",
    rent: "Rent",
    search: "Search",
    news: "Blog",
    services: "Services",
    contacts: "Contacts",
    about: "About us"
}