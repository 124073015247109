import React, { Component } from "react";
import axios from "axios";
import { globalhostapi } from "./../config/variables"
import toastr from "toastr";
import { Link } from "react-router-dom";

import ManagementNav from "./../Components/ManagementNav";

class ManagementRegionsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allRegions: [],
            allPlaces: [],
        };

        this.deleteRegion = this.deleteRegion.bind(this);
        this.deletePlace = this.deletePlace.bind(this);
    }

    componentDidMount() {
        axios
            .get(globalhostapi + "region/all")
            .then((response) => {
                this.setState({
                    allRegions: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        axios
            .get(globalhostapi + "place/all")
            .then((response) => {
                this.setState({
                    allPlaces: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    deleteRegion(id, region) {
        if (window.confirm("Изтрий " + region + "?")) {
            axios
                .delete(globalhostapi + "region/delete/" + id)
                .then(() => {
                    axios
                        .get(globalhostapi + "region/all")
                        .then((response) => {
                            this.setState({
                                allRegions: response.data,
                            });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    toastr.success("Успешно изтрит регион.");
                })
                .catch(() => {
                    toastr.warning("Неуспешно изтриване на регион.");
                });
        }
    }

    deletePlace(id, place) {
        if (window.confirm("Изтрий " + place + "?")) {
            axios
                .delete(globalhostapi + "place/delete/" + id)
                .then(() => {
                    axios
                        .get(globalhostapi + "place/all")
                        .then((response) => {
                            this.setState({
                                allPlaces: response.data,
                            });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    toastr.success("Успешно изтрит субрегион.");
                })
                .catch(() => {
                    toastr.warning("Неуспешно изтриването на субрегион.");
                });
        }
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="addRegion shadow">
                            <h3>Всички региони</h3>
                            <br />
                            <Link to="/management/region/create">
                                <button className="addButton">
                                    Добави регион
                                </button>
                            </Link>
                            <br />
                            {this.state.allRegions.length > 0
                                ? this.state.allRegions.map((region, id) => {
                                      return (
                                          <div className="type" key={id}>
                                              {id + 1}. {region.bg_city}
                                              <button
                                                  onClick={() => {
                                                      this.deleteRegion(
                                                          region.id,
                                                          region.bg_city
                                                      );
                                                  }}
                                                  className="deleteButton"
                                              >
                                                  Премахни
                                              </button>
                                              <Link
                                                  to={
                                                      "/management/region/edit/" +
                                                      region.id
                                                  }
                                              >
                                                  <button className="editButton">
                                                      Редактирай
                                                  </button>
                                              </Link>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                        <div className="listProperties shadow">
                            <h3>Всички субрегиони</h3>
                            <br />
                            <Link to="/management/place/create">
                                <button className="addButton">
                                    Добави субрегион
                                </button>
                            </Link>
                            <br />
                            {this.state.allPlaces.length > 0
                                ? this.state.allPlaces.map((place, id) => {
                                      return (
                                          <div className="type" key={id}>
                                              {id + 1}. {place.bg_city}
                                              <button
                                                  onClick={() => {
                                                      this.deletePlace(
                                                          place.id,
                                                          place.bg_city
                                                      );
                                                  }}
                                                  className="deleteButton"
                                              >
                                                  Премахни
                                              </button>
                                              <Link
                                                  to={
                                                      "/management/place/edit/" +
                                                      place.id
                                                  }
                                              >
                                                  <button className="editButton">
                                                      Редактирай
                                                  </button>
                                              </Link>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementRegionsPage;
