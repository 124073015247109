import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { globalhostapi } from "./../config/variables";
import { i18bg, i18en } from "./../locale/landingsearch";

class LandingSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allProperties: 0,
            filterTransaction: 0,
            filterType: 0,
            filterRegion: 0,
            filterPrice: 0,

            allTypes: [],
            allRegions: [],

            sliderImages: [
                "https://test.homes-bg.com/images/landing/1-hd.webp",
                "https://test.homes-bg.com/images/landing/2-hd.webp",
                "https://test.homes-bg.com/images/landing/3-hd.webp",
                "https://test.homes-bg.com/images/landing/4-hd.webp"
            ],
            randomSliderImage: "",
            loadBgAnimation: false,
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.calcProperties = this.calcProperties.bind(this);
    }

    componentDidMount() {
        this.setState({
            randomSliderImage: this.state.sliderImages[
                Math.floor(Math.random() * this.state.sliderImages.length)
            ],
        });

        axios(globalhostapi + "property/all/count").then((response) => {
            this.setState({
                allProperties: response.data,
                loadBgAnimation: true,
            });
        });

        axios(globalhostapi + "subtype/all").then((response) => {
            this.setState({
                allTypes: response.data,
            });
        });

        axios(globalhostapi + "region/all").then((response) => {
            this.setState({
                allRegions: response.data,
            });
        });
    }

    calcProperties() {
        const formValues = new FormData();

        if (this.state.filterTransaction > 0) {
            formValues.append("transaction_id", this.state.filterTransaction);
        }

        if (this.state.filterType > 0) {
            formValues.append("type_id", this.state.filterType);
        }

        if (this.state.filterRegion > 0) {
            formValues.append("place_id", this.state.filterRegion);
        }

        if (this.state.filterPrice > 0) {
            formValues.append("price", this.state.filterPrice);
        }

        axios
            .post(globalhostapi + "property/all/search", formValues)
            .then((response) => {
                this.setState({
                    allProperties: response.data,
                });
            })
            .catch((error) => console.log(error));
    }

    handleSearch(e) {
        e.preventDefault();

        let offer = this.state.filterTransaction;
        let type = this.state.filterType;
        let region = this.state.filterRegion;
        let price = this.state.filterPrice;

        let searchString = "/search";

        if (offer > 0) {
            if (searchString.endsWith("/search")) {
                searchString += `?offer=${offer}`;
            }
        }

        if (type > 0) {
            if (searchString.endsWith("/search")) {
                searchString += `?type=${type}`;
            } else {
                searchString += `&type=${type}`;
            }
        }

        if (region > 0) {
            if (searchString.endsWith("/search")) {
                searchString += `?region=${region}`;
            } else {
                searchString += `&region=${region}`;
            }
        }

        if (price > 0) {
            if (searchString.endsWith("/search")) {
                searchString += `?price=${price}`;
            } else {
                searchString += `&price=${price}`;
            }
        }

        this.props.history.push(searchString);
    }

    render() {
        return (
            <div className="block s-fs">
                <div
                    className={`${
                        this.state.loadBgAnimation ? "animationEaseOut" : ""
                    }`}
                    style={{
                        backgroundImage: `url(${this.state.randomSliderImage})`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        width: "100%",
                        height: "723px",
                    }}
                >
                    <div
                        className="block s-md"
                        style={{
                            height: 723,
                        }}
                    >
                        <div className="landingSearchForm">
                            <form onSubmit={this.handleSearch}>
                                <select
                                    className="shadow"
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                filterTransaction:
                                                    e.target.value,
                                            },
                                            () => {
                                                this.calcProperties();
                                            }
                                        );
                                    }}
                                >
                                    <option value="0">
                                        {this.props.lang === "bg"
                                            ? i18bg.saleorrent
                                            : i18en.saleorrent}
                                    </option>
                                    <option value="1">
                                        {this.props.lang === "bg"
                                            ? i18bg.sale
                                            : i18en.sale}
                                    </option>
                                    <option value="2">
                                        {this.props.lang === "bg"
                                            ? i18bg.rent
                                            : i18en.rent}
                                    </option>
                                </select>
                                <select
                                    style={{ marginLeft: 35 }}
                                    className="shadow"
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                filterType: e.target.value,
                                            },
                                            () => {
                                                this.calcProperties();
                                            }
                                        );
                                    }}
                                    value={this.state.filterType}
                                >
                                    <option value="0">
                                        {this.props.lang === "bg"
                                            ? i18bg.propertytype
                                            : i18en.propertytype}
                                    </option>
                                    {this.state.allTypes.length > 0 &&
                                        this.state.allTypes.map((type, i) => {
                                            return (
                                                <option value={type.id} key={i}>
                                                    {this.props.lang === "bg"
                                                        ? type.bg_name
                                                        : type.en_name}
                                                </option>
                                            );
                                        })}
                                </select>
                                <select
                                    style={{ marginBottom: 35, marginTop: 35 }}
                                    className="shadow"
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                filterRegion: e.target.value,
                                            },
                                            () => {
                                                this.calcProperties();
                                            }
                                        );
                                    }}
                                    value={this.state.filterRegion}
                                >
                                    <option value="0">
                                        {this.props.lang === "bg"
                                            ? i18bg.propertylocation
                                            : i18en.propertylocation}
                                    </option>
                                    {this.state.allRegions.length > 0 &&
                                        this.state.allRegions.map(
                                            (region, i) => {
                                                return (
                                                    <option
                                                        value={region.id}
                                                        key={i}
                                                    >
                                                        {this.props.lang ===
                                                        "bg"
                                                            ? region.bg_city
                                                            : region.en_city}
                                                    </option>
                                                );
                                            }
                                        )}
                                </select>
                                <select
                                    style={{ marginLeft: 35, marginBottom: 35 }}
                                    className="shadow"
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                filterPrice: e.target.value,
                                            },
                                            () => {
                                                this.calcProperties();
                                            }
                                        );
                                    }}
                                >
                                    <option value="0">
                                        {this.props.lang === "bg"
                                            ? i18bg.price
                                            : i18en.price}
                                    </option>
                                    <option value="1">
                                        {this.props.lang === "bg"
                                            ? "До € 20 000"
                                            : "Up to € 20 000"}
                                    </option>
                                    <option value="2">
                                        € 20 000 - € 50 000
                                    </option>
                                    <option value="3">
                                        € 50 000 - € 100 000
                                    </option>
                                    <option value="4">
                                        € 100 000 - € 200 000
                                    </option>
                                    <option value="5">
                                        € 200 000 - € 500 000
                                    </option>
                                    <option value="6">
                                        {this.props.lang === "bg"
                                            ? "Над € 500 000"
                                            : "Up € 500 000"}
                                    </option>
                                </select>
                                <input
                                    type="submit"
                                    value={
                                        this.state.allProperties > 0
                                            ? this.props.lang === "bg"
                                                ? i18bg.searchbuttonstart +
                                                  this.state.allProperties +
                                                  i18bg.searchbuttonend
                                                : i18en.searchbuttonstart +
                                                  this.state.allProperties +
                                                  i18en.searchbuttonend
                                            : this.props.lang === "bg"
                                            ? i18bg.searchbuttondefault
                                            : i18en.searchbuttondefault
                                    }
                                    className="shadow"
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang,
    };
};

export default withRouter(connect(mapStateToProps)(LandingSearch));
