import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { i18bg, i18en } from "./../locale/subheader";
import { actionSetBG, actionSetEN } from "./../actions/lang";

class Subheader extends Component {
    render() {
        return (
            <div
                className="block s-fs"
                style={{ marginTop: 90, backgroundColor: "#3e4095" }}
            >
                <div className="block s-md">
                    <ul className="block submenu">
                        <li className="item">
                            <Link to="/search?offer=2&type=0&region=29&price=0&order_by=desc">
                                {this.props.lang === "bg"
                                    ? i18bg.accommodation_in_vt
                                    : i18en.accommodation_in_vt}
                            </Link>
                            <ul>
                                <li>
                                    <Link to="/search?offer=2&type=33&region=&price=&order_by=desc">
                                        {this.props.lang === "bg"
                                            ? i18bg.rooms
                                            : i18en.rooms}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/search?offer=2&type=1&region=0&price=0&order_by=desc">
                                        {this.props.lang === "bg"
                                            ? i18bg.houses
                                            : i18en.houses}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/search?offer=2&type=10&region=&price=&order_by=desc">
                                        {this.props.lang === "bg"
                                            ? i18bg.offices
                                            : i18en.offices}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/search?offer=2&type=18&region=&price=&order_by=desc">
                                        {this.props.lang === "bg"
                                            ? i18bg.apartments
                                            : i18en.apartments}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/search?offer=2&type=13&region=&price=&order_by=desc">
                                        {this.props.lang === "bg"
                                            ? i18bg.shops
                                            : i18en.shops}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/search?offer=2&type=29&region=&price=&order_by=desc">
                                        {this.props.lang === "bg"
                                            ? i18bg.restourants
                                            : i18en.restourants}
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="item">
                            <Link to="/search?offer=1&type=19&region=29&price=0&order_by=desc">
                                {this.props.lang === "bg"
                                    ? i18bg.apartments_in_vt
                                    : i18en.apartments_in_vt}
                            </Link>
                        </li>
                        <li className="item">
                            <Link to="/search?offer=0&type=0&region=0&price=0&promo=checked&order_by=desc">
                                {this.props.lang === "bg"
                                    ? i18bg.promo_prices
                                    : i18en.promo_prices}
                            </Link>
                        </li>
                        <li className="item">
                            <Link to="/search?offer=0&type=0&region=0&price=0&virtualtour=checked&order_by=desc">
                                {this.props.lang === "bg"
                                    ? i18bg.properties_virtualtour
                                    : i18en.properties_virtualtour}
                            </Link>
                        </li>
                        <li className="item" style={{ border: 0 }}>
                            <Link to="/post/25">
                                {this.props.lang === "bg"
                                    ? i18bg.exclusive_seller
                                    : i18en.exclusive_seller}
                            </Link>
                        </li>
                        <li
                            className={"item " + this.props.lang}
                            onClick={() => {
                                if (this.props.lang === "bg") {
                                    this.props.setLangEN();
                                } else {
                                    this.props.setLangBG();
                                }
                            }}
                        ></li>
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setLangBG: () => dispatch(actionSetBG()),
    setLangEN: () => dispatch(actionSetEN()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subheader);
