import {
    SET_BG,
    SET_EN
} from './../actions/lang'

let checkLang = localStorage.getItem('lang')
let userLang = "bg"
if (checkLang !== null) {
    userLang = localStorage.getItem('lang')
}

let initialState = {
    lang: userLang
}

export function lang(state = initialState, action) {

    switch (action.type) {

        case SET_BG:

            return Object.assign({}, state, {
                lang: action.lang
            })

        case SET_EN:

            return Object.assign({}, state, {
                lang: action.lang
            })

        default:

            return initialState
    }
}