import React, { Component } from "react";
import axios from "axios";
import { globalhostapi } from "./config/variables";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ScrollToTop from "./helpers/scrollToTop";
import GA from "./utils/GoogleAnalytics";
import asyncComponent from "./hoc/asyncComponent";

import Header from "./Components/Header";
import Subheader from "./Components/Subheader";
import Footer from "./Components/Footer";

import LandingPage from "./Pages/LandingPage";
import PropertyManagementPage from "./Pages/PropertyManagementPage";

import PrivateComponent from "./Components/PrivateComponent";
import LoginComponent from "./Components/LoginComponent";

import ManagementPage from "./Pages/ManagementPage";
import ManagementPropertiesPage from "./Pages/ManagementPropertiesPage";
import ManagementImportPage from "./Pages/ManagementImportPage";
import ManagementTypesPage from "./Pages/ManagementTypesPage";
import ManagementRegionsPage from "./Pages/ManagementRegionsPage";
import ManagementBrokersPage from "./Pages/ManagementBrokersPage";
import ManagementBlogPage from "./Pages/ManagementBlogPage";
import ManagementPagesPage from "./Pages/ManagementPagesPage";
import ManagementUsersPage from "./Pages/ManagementUsersPage";

import ManagementPropertiesEditPage from "./Pages/ManagementPropertiesEditPage";
import ManagementTypesCreatePage from "./Pages/ManagementTypesCreatePage";
import ManagementTypesEditPage from "./Pages/ManagementTypesEditPage";
import ManagementSubtypesCreatePage from "./Pages/ManagementSubtypesCreatePage";
import ManagementSubtypesEditPage from "./Pages/ManagementSubtypesEditPage";
import ManagementRegionsCreatePage from "./Pages/ManagementRegionsCreatePage";
import ManagementRegionsEditPage from "./Pages/ManagementRegionsEditPage";
import ManagementPlacesCreatePage from "./Pages/ManagementPlacesCreatePage";
import ManagementPlacesEditPage from "./Pages/ManagementPlacesEditPage";
import ManagementBrokersEditPage from "./Pages/ManagementBrokersEditPage";
import ManagementBlogEditBgPage from "./Pages/ManagementBlogEditBgPage";
import ManagementBlogEditEnPage from "./Pages/ManagementBlogEditEnPage";
import ManagementUsersEditPage from "./Pages/ManagementUsersEditPage";

import "./App.scss";

const AsyncServicesPage = asyncComponent(() => {
    return import("./Pages/ServicesPage");
});
const AsyncContactsPage = asyncComponent(() => {
    return import("./Pages/ContactsPage");
});
const AsyncAboutUsPage = asyncComponent(() => {
    return import("./Pages/AboutUsPage");
});
const AsyncBlogPage = asyncComponent(() => {
    return import("./Pages/BlogPage");
});
const AsyncBuyPage = asyncComponent(() => {
    return import("./Pages/BuyPage");
});
const AsyncSellPage = asyncComponent(() => {
    return import("./Pages/SellPage");
});
const AsyncRentPage = asyncComponent(() => {
    return import("./Pages/RentPage");
});
const AsyncSearchPage = asyncComponent(() => {
    return import("./Pages/SearchPage");
});
const AsyncSinglePropertyPage = asyncComponent(() => {
    return import("./Pages/SinglePropertyPage");
});
const AsyncSinglePostPage = asyncComponent(() => {
    return import("./Pages/SinglePostPage");
});

class App extends Component {
    constructor() {
        super();

        this.state = {
            token: false,
        };
    }

    componentDidMount() {
        const storage = JSON.parse(localStorage.getItem("user"));
        if (typeof storage === "object" && storage !== null) {
            axios
                .get(globalhostapi + "auth/token/" + storage?.token)
                .then((response) => {
                    if (response.data === true) {
                        this.setState({
                            token: true,
                        });
                    } else {
                        localStorage.removeItem("user");
                    }
                })
                .catch((error) => console.log(error));
        } else {
            this.setState({
                token: false,
            });
            localStorage.getItem("user");
        }
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <meta charSet="utf-8" />
                </Helmet>
                <Router>
                    {GA.init() && <GA.RouteTracker />}
                    <ScrollToTop>
                        <Header />
                        <Subheader />
                        <Route exact path="/" component={LandingPage} />
                        <Route path="/services" component={AsyncServicesPage} />
                        <Route path="/contacts" component={AsyncContactsPage} />
                        <Route path="/about-us" component={AsyncAboutUsPage} />
                        <Route path="/blog" component={AsyncBlogPage} />

                        <Route path="/buy-property" component={AsyncBuyPage} />
                        <Route
                            path="/sell-property"
                            component={AsyncSellPage}
                        />
                        <Route
                            path="/rent-property"
                            component={AsyncRentPage}
                        />

                        <Route
                            path="/property-management"
                            component={PropertyManagementPage}
                        />

                        <LoginComponent
                            exact
                            path="/management"
                            component={ManagementPage}
                            token={this.state.token}
                        />

                        <PrivateComponent
                            path="/management/properties"
                            component={ManagementPropertiesPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/import"
                            component={ManagementImportPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/types"
                            component={ManagementTypesPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/regions"
                            component={ManagementRegionsPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/brokers"
                            component={ManagementBrokersPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/blog"
                            component={ManagementBlogPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/pages"
                            component={ManagementPagesPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/users"
                            component={ManagementUsersPage}
                            token={this.state.token}
                        />

                        <PrivateComponent
                            path="/management/property/edit/:id"
                            component={ManagementPropertiesEditPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/type/create"
                            component={ManagementTypesCreatePage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/type/edit/:id"
                            component={ManagementTypesEditPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/subtype/create"
                            component={ManagementSubtypesCreatePage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/subtype/edit/:id"
                            component={ManagementSubtypesEditPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/region/create"
                            component={ManagementRegionsCreatePage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/region/edit/:id"
                            component={ManagementRegionsEditPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/place/create"
                            component={ManagementPlacesCreatePage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/place/edit/:id"
                            component={ManagementPlacesEditPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/broker/edit/:id"
                            component={ManagementBrokersEditPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/post/edit/bg/:id"
                            component={ManagementBlogEditBgPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/post/edit/en/:id"
                            component={ManagementBlogEditEnPage}
                            token={this.state.token}
                        />
                        <PrivateComponent
                            path="/management/user/edit/:id"
                            component={ManagementUsersEditPage}
                            token={this.state.token}
                        />

                        <Route path="/search" component={AsyncSearchPage} />
                        <Route
                            path="/property/:id"
                            component={AsyncSinglePropertyPage}
                        />
                        <Route
                            path="/post/:id"
                            component={AsyncSinglePostPage}
                        />

                        <Footer />
                    </ScrollToTop>
                </Router>
            </div>
        );
    }
}

export default App;
