import React, { Component } from "react";
import axios from "axios";
import { globalhosturl, globalhostapi } from "./../config/variables"
import toastr from "toastr";
import ManagementNav from "./../Components/ManagementNav";

class ManagementBrokersEditPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            code: "",
            bg_names: "",
            en_names: "",
            email: "",
            phone: "",
            mobile: "",
            image: "",
            new_image: "",
            at_homepage: "",
            homepage_priority: ""
        };

        this.editBroker = this.editBroker.bind(this);
        this.deletePicture = this.deletePicture.bind(this);
    }

    componentDidMount() {
        axios
            .get(
                globalhostapi + "broker/one/" +
                    this.props.match.params.id
            )
            .then((response) => {
                this.setState({
                    id: response.data.id,
                    code: response.data.code,
                    bg_names: response.data.bg_names,
                    en_names: response.data.en_names,
                    email: response.data.email,
                    phone: response.data.phone,
                    mobile: response.data.mobile,
                    image: response.data.pictures,
                    new_image: response.data.pictures,
                    at_homepage: response.data.at_homepage,
                    homepage_priority: response.data.homepage_priority
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    editBroker(e) {
        const formValues = new FormData();

        formValues.append("code", this.state.code);
        formValues.append("bg_names", this.state.bg_names);
        formValues.append("en_names", this.state.en_names);
        formValues.append("email", this.state.email);
        formValues.append("phone", this.state.phone);
        formValues.append("mobile", this.state.mobile);
        if (this.state.new_image !== this.state.image) {
            formValues.append("image", this.state.new_image);
        } else {
            formValues.append("image", this.state.image);
        }
        formValues.append("at_homepage", this.state.at_homepage)
        formValues.append("homepage_priority", this.state.homepage_priority)

        axios
            .post(
                globalhostapi + "broker/edit/" +
                    this.props.match.params.id,
                formValues
            )
            .then(() => {
                axios
                    .get(
                        globalhostapi + "broker/one/" +
                            this.props.match.params.id
                    )
                    .then((response) => {
                        this.setState({
                            id: response.data.id,
                            code: response.data.code,
                            bg_names: response.data.bg_names,
                            en_names: response.data.en_names,
                            email: response.data.email,
                            phone: response.data.phone,
                            mobile: response.data.mobile,
                            image: response.data.pictures,
                            new_image: response.data.pictures,
                            at_homepage: response.data.at_homepage,
                            homepage_priority: response.data.homepage_priority
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                toastr.success("Успешно редактиран брокер.");
            })
            .catch(() => {
                toastr.warning("Неуспешно редактирането на брокер.");
            });
    }

    deletePicture(e) {
        e.preventDefault();

        if (window.confirm("Изтрий снимката?")) {
            axios
                .delete(
                    globalhostapi + "broker/delete/" +
                        this.state.id +
                        "/picture"
                )
                .then(() => {
                    toastr.success("Успешно изтрита снимка на брокер.");
                })
                .catch(() => {
                    toastr.warning("Неуспешно изтриването на снимката.");
                });
        }
    }

    selectImage = (event) => {
        this.setState({ new_image: event.target.files[0] });
    };

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="addBroker shadow">
                            <h3>Редактирай брокер</h3>
                            <br />
                            <label htmlFor="code">Код на брокера*</label>
                            <input
                                type="text"
                                name="code"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        code: e.target.value,
                                    });
                                }}
                                value={this.state.code}
                                required="required"
                            />
                            <label htmlFor="bg_names">
                                Имена на български*
                            </label>
                            <input
                                type="text"
                                name="bg_names"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        bg_names: e.target.value,
                                    });
                                }}
                                value={this.state.bg_names}
                                required="required"
                            />
                            <label htmlFor="en_names">
                                Имена на английски*
                            </label>
                            <input
                                type="text"
                                name="en_names"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        en_names: e.target.value,
                                    });
                                }}
                                value={this.state.en_names}
                                required="required"
                            />
                            <label htmlFor="email">E-mail адрес*</label>
                            <input
                                type="text"
                                name="email"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        email: e.target.value,
                                    });
                                }}
                                value={this.state.email}
                                required="required"
                            />
                            <label htmlFor="phone">Телефон</label>
                            <input
                                type="text"
                                name="phone"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        phone: e.target.value,
                                    });
                                }}
                                value={this.state.phone}
                                required="required"
                            />
                            <label htmlFor="mobile">Мобилен телефон</label>
                            <input
                                type="text"
                                name="mobile"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        mobile: e.target.value,
                                    });
                                }}
                                value={this.state.mobile}
                                required="required"
                            />
                            <label htmlFor="at_homepage">Видим в начална страница</label>
                            <select 
                                onChange={(e) => {
                                    this.setState({
                                        at_homepage: e.target.value,
                                    });
                                }}
                                value={this.state.at_homepage}
                                required="required"
                                >
                                    <option value="0">Не</option>
                                    <option value="1">Да</option>
                            </select>
                            <label htmlFor="homepage_priority">Подреждане на начална страница</label>
                            <input
                                type="text"
                                name="homepage_priority"
                                placeholder="1 е най-високото, 2, 3... към най-ниското"
                                onChange={(e) => {
                                    this.setState({
                                        homepage_priority: e.target.value,
                                    });
                                }}
                                value={this.state.homepage_priority}
                                required="required"
                            />
                            {this.state.image.length > 0 && (
                                <img
                                    className="profilePicture"
                                    src={
                                        globalhosturl +
                                        this.state.image.replace(
                                            "public",
                                            "storage"
                                        )
                                    }
                                    alt="Avatar"
                                />
                            )}
                            <label htmlFor="file">Добави снимка</label>
                            <input type="file" onChange={this.selectImage} />
                            {this.state.image.length > 0 && (
                                <button
                                    className="deleteProfilePicture"
                                    onClick={this.deletePicture}
                                >
                                    Изтрий снимката
                                </button>
                            )}
                            <input
                                type="button"
                                onClick={this.editBroker}
                                value="Редактирай"
                            />
                        </div>
                        <div className="listProperties shadow"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementBrokersEditPage;
