import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { globalhostapi } from "../config/variables";
import toastr from "toastr";

import ManagementNav from "../Components/ManagementNav";

class ManagementImportPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allArchives: [],
        };

        this.importArchive = this.importArchive.bind(this);
    }

    componentDidMount() {
        axios
            .get(globalhostapi + "import/all")
            .then((response) => {
                this.setState({
                    allArchives: response.data,
                });
            })
            .catch((error) => console.log(error));
    }

    importArchive(archiveName) {
        axios
            .post(globalhostapi + "import/unzipArchive", {
                archiveName: archiveName.replace("public/import/", ""),
            })
            .then((response) => {
                toastr.success("Успешно импортиране!");
            })
            .catch((error) => toastr.error("Неуспешно импортиране. " + error));
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="listArchives shadow">
                            <h3>Всички архиви</h3>

                            <br />
                            <br />

                            {this.state.allArchives.length > 0
                                ? this.state.allArchives.map((archive, id) => {
                                      return (
                                          <div className="archive" key={id}>
                                              {archive}

                                              <button className="deleteButton">
                                                  Изтрий
                                              </button>
                                              <button
                                                  className="editButton"
                                                  onClick={() => {
                                                      this.importArchive(
                                                          archive
                                                      );
                                                  }}
                                              >
                                                  Импортирай
                                              </button>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.auth,
    };
};

export default connect(mapStateToProps)(ManagementImportPage);
