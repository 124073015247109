export const i18bg = {
    title: "Екип",
    ttodorov: "Тодор Тодоров",
    mtodorova: "Мариана Тодорова",
    rnikolova: "Румяна Николова",
    szhechev: "Стоян Жечев",
    kvelikova: "Катя Великова",
    iivanova: "Ивелина Иванова",
    dtsachev: "Димитър Цачев",
    iyordanova: "Илка Йорданова"
}

export const i18en = {
    title: "Team",
    ttodorov: "Todor Todorov",
    mtodorova: "Mariana Todorova",
    rnikolova: "Rumyana Nikolova",
    szhechev: "Stoyan Zhechev",
    kvelikova: "Katya Velikova",
    iivanova: "Ivelina Ivanova",
    dtsachev: "Dimitar Tsachev",
    iyordanova: "Ilka Yordanova"
}