import React, { Component } from "react";
import axios from "axios";
import { globalhostapi } from "./../config/variables";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import toastr from "toastr";
import ManagementNav from "./../Components/ManagementNav";

class ManagementPagesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allPages: [],
            id: 0,
            title_bg: "",
            description_bg: "",
            title_en: "",
            description_en: "",
            visible: "checked",
        };

        this.editPage = this.editPage.bind(this);
        this.loadPageDetails = this.loadPageDetails.bind(this);
    }

    componentDidMount() {
        axios
            .get(globalhostapi + "pages/all")
            .then((response) => {
                this.setState({
                    allPages: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    loadPageDetails(page) {
        console.log(page)
        this.setState({
            id: page.id,
            title_bg: page.title_bg,
            description_bg: page.description_bg,
            title_en: page.title_en,
            description_en: page.description_en,
            visible: page.visible === "Y" ? "checked" : "",
        });
    }

    editPage(e) {
        const formValues = new FormData();

        formValues.append("id", this.state.id);
        formValues.append("title_bg", this.state.title_bg);
        formValues.append("description_bg", this.state.description_bg);
        formValues.append("title_en", this.state.title_en);
        formValues.append("description_en", this.state.description_en);
        formValues.append(
            "visible",
            this.state.visible === "checked" ? "Y" : "N"
        );

        axios
            .post(
                globalhostapi + "pages/edit",
                formValues
            )
            .then(() => {
                toastr.success("Успешно редактирана страница.");
            })
            .catch((error) => {
                toastr.warning("Неуспешно редактирането на страница.");
            });
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="addPage shadow">
                            <h3>Редактирай страница</h3>
                            <br />
                            <label htmlFor="title">Заглавие</label>
                            <input
                                type="text"
                                name="title"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        title_bg: e.target.value,
                                    });
                                }}
                                value={this.state.title_bg}
                                required="required"
                            />

                            <label
                                htmlFor="description_bg"
                                style={{ marginBottom: 15 }}
                            >
                                Текст към новината
                            </label>
                            <CKEditor
                                data={this.state.description_bg}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.setState({
                                        description_bg: data,
                                    });
                                }}
                                required="required"
                            />

                            <label htmlFor="title">Title</label>
                            <input
                                type="text"
                                name="title_en"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        title_en: e.target.value,
                                    });
                                }}
                                value={this.state.title_en}
                                required="required"
                            />

                            <label
                                htmlFor="description_en"
                                style={{ marginBottom: 15 }}
                            >
                                Text to the news
                            </label>
                            <CKEditor
                                data={this.state.description_en}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.setState({
                                        description_en: data,
                                    });
                                }}
                                required="required"
                            />

                            <label htmlFor="visible">Показване</label>
                            <input
                                type="checkbox"
                                name="visible"
                                checked={this.state.visible}
                                onChange={() => {
                                    if (this.state.visible === "checked") {
                                        this.setState({
                                            visible: "",
                                        });
                                    } else {
                                        this.setState({
                                            visible: "checked",
                                        });
                                    }
                                }}
                            />

                            <input
                                type="button"
                                onClick={this.editPage}
                                value="Редактирай"
                            />
                        </div>
                        <div className="listProperties shadow">
                            <h3>Всички страници</h3>
                            <br />
                            {this.state.allPages.length > 0
                                ? this.state.allPages.map((page, id) => {
                                      return (
                                          <div className="property" key={id}>
                                              {page.title_bg}
                                              <button
                                                  className="editButton"
                                                  onClick={() => this.loadPageDetails(
                                                      page
                                                  )}
                                              >
                                                  Редактирай
                                              </button>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementPagesPage;
