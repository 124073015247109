import { combineReducers } from 'redux'

import { auth } from './auth.js'
import { lang } from './lang.js'
import { properties } from './properties.js'

export default combineReducers({
    auth,
    lang,
    properties
})