import React, { Component } from "react";
import axios from "axios";
import { globalhostapi } from "./../config/variables"
import toastr from "toastr";
import { Link } from "react-router-dom";
import ManagementNav from "../Components/ManagementNav";

class ManagementTypesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allTypes: [],
            allSubtypes: [],
        };

        this.deleteType = this.deleteType.bind(this);
        this.deleteSubtype = this.deleteSubtype.bind(this);
    }

    componentDidMount() {
        axios
            .get(globalhostapi + "type/all")
            .then((response) => {
                this.setState({
                    allTypes: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        axios
            .get(globalhostapi + "subtype/all")
            .then((response) => {
                this.setState({
                    allSubtypes: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    deleteType(id, type) {
        if (window.confirm("Изтрий " + type + "?")) {
            axios
                .delete(globalhostapi + "type/delete/" + id)
                .then((res) => {
                    axios
                        .get(globalhostapi + "type/all")
                        .then((response) => {
                            this.setState({
                                allTypes: response.data,
                            });

                            axios
                                .get(globalhostapi + "type/all")
                                .then((response) => {
                                    this.setState({
                                        allTypes: response.data,
                                    });
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                            toastr.success("Успешно изтрит: " + type + ".");
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
                .catch((err) => {
                    toastr.warning("Неуспешно изтриване на: " + type + ".");
                });
        }
    }

    deleteSubtype(id, subtype) {
        if (window.confirm("Изтрий " + subtype + "?")) {
            axios
                .delete(globalhostapi + "subtype/delete/" + id)
                .then(() => {
                    axios
                        .get(globalhostapi + "subtype/all")
                        .then((response1) => {
                            this.setState({
                                allSubtypes: response1.data,
                            });

                            axios
                                .get(globalhostapi + "subtype/all")
                                .then((response2) => {
                                    this.setState({
                                        allSubtypes: response2.data,
                                    });
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                            toastr.success("Успешно изтрит: " + subtype + ".");
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
                .catch((err) => {
                    toastr.warning("Неуспешно изтриване на: " + subtype + ".");
                });
        }
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="addType shadow">
                            <h3>Типове имоти</h3>
                            <br />
                            <Link to="/management/type/create">
                                <button className="addButton">
                                    Добави тип имот
                                </button>
                            </Link>
                            <br />
                            {this.state.allTypes.length > 0
                                ? this.state.allTypes.map((type, id) => {
                                      return (
                                          <div className="type" key={id}>
                                              {type.bg_name}

                                              <button
                                                  onClick={() => {
                                                      this.deleteType(
                                                          type.id,
                                                          type.bg_name
                                                      );
                                                  }}
                                                  className="deleteButton"
                                              >
                                                  Изтрий
                                              </button>

                                              <Link
                                                  to={
                                                      "/management/type/edit/" +
                                                      type.id
                                                  }
                                              >
                                                  <button className="editButton">
                                                      Редактирай
                                                  </button>
                                              </Link>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                        <div className="listTypes shadow">
                            <h3>Подтипове имоти</h3>
                            <br />
                            <Link to="/management/subtype/create">
                                <button className="addButton">
                                    Добави подтип имот
                                </button>
                            </Link>
                            <br />
                            {this.state.allSubtypes.length > 0
                                ? this.state.allSubtypes.map((subtype, id) => {
                                      return (
                                          <div className="type" key={id}>
                                              {subtype.bg_name}

                                              <button
                                                  onClick={() => {
                                                      this.deleteSubtype(
                                                          subtype.id,
                                                          subtype.bg_name
                                                      );
                                                  }}
                                                  className="deleteButton"
                                              >
                                                  Изтрий
                                              </button>

                                              <Link
                                                  to={
                                                      "/management/subtype/edit/" +
                                                      subtype.id
                                                  }
                                              >
                                                  <button className="editButton">
                                                      Редактирай
                                                  </button>
                                              </Link>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementTypesPage;
