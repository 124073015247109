import React, { Component } from "react";
import axios from "axios";
import { globalhostapi, globalhosturl } from "./../config/variables";
import { connect } from "react-redux";

import { i18bg, i18en } from "./../locale/landingteam";

class LandingTeam extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allBrokers: [],
        };
    }

    componentDidMount() {
        axios
            .get(globalhostapi + "broker/all")
            .then((response) => {
                this.setState({
                    allBrokers: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div className="block s-fs">
                <center>
                    <hr style={{ width: 140, marginTop: 78 }} />
                </center>
                <h1 className="landingTeamTitle">
                    {this.props.lang === "bg" ? i18bg.title : i18en.title}
                </h1>
                <div className="block s-md">
                    <div className="landingTeam">
                        <div className="teamItems">
                            {this.state.allBrokers
                                .filter((broker) => broker.at_homepage === 1)
                                .sort(
                                    (a, b) =>
                                        a["homepage_priority"] -
                                        b["homepage_priority"]
                                )
                                .map((broker, i) => {
                                    let brokerPicture =
                                        globalhosturl +
                                        broker.pictures.replace(
                                            "public",
                                            "storage"
                                        );

                                    return (
                                        <div className="item shadow" key={i}>
                                            <img
                                                src={brokerPicture}
                                                alt={
                                                    this.props.lang === "bg"
                                                        ? broker.bg_names
                                                        : broker.en_names
                                                }
                                            />
                                            <div className="name">
                                                {this.props.lang === "bg"
                                                    ? broker.bg_names
                                                    : broker.en_names}
                                            </div>
                                            <div className="email">
                                                <a
                                                    href={
                                                        "mailto:" + broker.email
                                                    }
                                                >
                                                    {broker.email}
                                                </a>
                                            </div>
                                            <center>
                                                <div className="phone shadow">
                                                    <a
                                                        href={
                                                            "tel:" +
                                                            broker.mobile
                                                        }
                                                    >
                                                        {broker.mobile}
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang.lang,
    };
};

export default connect(mapStateToProps)(LandingTeam);
