export const i18bg = {
    policy: "Политика за поверителност",
    toc: "Правила и условия",
    aboutus: "За нас",
    faq: "Често задавани въпроси",
    office: "Нашият офис",
    services: "Услуги",
    subscribe: "Абониране"
}

export const i18en = {
    policy: "Privacy policy",
    toc: "Terms and conditions",
    aboutus: "About us",
    faq: "Frequently asked questions",
    office: "Our office",
    services: "Services",
    subscribe: "Subscribe"
}