import React, { Component } from "react";
import axios from "axios";
import { globalhostapi } from "./../config/variables"
import toastr from "toastr";
import ManagementNav from "./../Components/ManagementNav";

class ManagementRegionsEditPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            bg_city: "",
            en_city: "",
            visible: "",
        };

        this.editRegion = this.editRegion.bind(this);
    }

    componentDidMount() {
        axios
            .get(
                globalhostapi + "region/one/" +
                    this.props.match.params.id
            )
            .then((response) => {
                this.setState({
                    id: response.data.id,
                    bg_city: response.data.bg_city,
                    en_city: response.data.en_city,
                    visible: response.data.visible === "checked" ? "Y" : "N",
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    editRegion() {
        axios
            .post(
                globalhostapi + "region/edit/" +
                    this.props.match.params.id,
                {
                    id: this.state.id,
                    bg_city: this.state.bg_city,
                    en_city: this.state.en_city,
                    visible: this.state.visible === "checked" ? "Y" : "N",
                }
            )
            .then(() => {
                axios
                .get(
                    globalhostapi + "region/one/" +
                        this.props.match.params.id
                )
                .then((response) => {
                    this.setState({
                        id: response.data.id,
                        bg_city: response.data.bg_city,
                        en_city: response.data.en_city,
                        visible: response.data.visible === "checked" ? "Y" : "N",
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
                toastr.success("Успешно редактиран регион.");
            })
            .catch(() => {
                toastr.warning("Неуспешно редактирането на регион.");
            });
    }

    render() {
        return (
            <div className="block s-fs">
                <div className="block s-md">
                    <ManagementNav />
                    <br />
                    <div className="managementContainer shadow">
                        <div className="addRegion shadow">
                            <h3>Редактирай регион</h3>
                            <br />
                            <label htmlFor="bg_city">Име на български</label>
                            <input
                                type="text"
                                name="bg_city"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        bg_city: e.target.value,
                                    });
                                }}
                                value={this.state.bg_city}
                                required="required"
                            />
                            <label htmlFor="en_city">Име на английски</label>
                            <input
                                type="text"
                                name="en_city"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        en_city: e.target.value,
                                    });
                                }}
                                value={this.state.en_city}
                                required="required"
                            />
                            <label htmlFor="visible">Показване</label>
                            <input
                                type="checkbox"
                                name="visible"
                                checked={this.state.visible}
                                onChange={() => {
                                    if (this.state.visible === "checked") {
                                        this.setState({
                                            visible: "",
                                        });
                                    } else {
                                        this.setState({
                                            visible: "checked",
                                        });
                                    }
                                }}
                            />
                            <input
                                type="button"
                                onClick={this.editRegion}
                                value="Редактирай"
                            />
                        </div>
                        <div className="listProperties shadow"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementRegionsEditPage;
